<template>
  <div>
    <div class="progress mb-3">
      <div class="progress-bar" role="progressbar" :aria-valuenow="value" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${value}%` }">
      </div>
    </div>

    <div class="small text-muted">
      {{ Math.round(value) }}%
      {{ message }}
      <a href="#" v-if="cancellable" style="color: inherit; text-decoration: underline;" @click.prevent="$emit('cancel')">cancel</a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: Number,
    message: String,
    cancellable: Boolean,
  },
}
</script>

<style scoped>

</style>

