import VideoSeeker from './video_seeker.js';
import GifSeeker from './gif_seeker.js';

export default class FrameExtractor {
  constructor(opts={}) {
    this.aborted = false;
    this.mimeType = opts.mimeType;
    this.rotation = opts.rotation;

    var onLoad = ({width, height, framesMeta, duration}) => {
      this.sourceWidth = width;
      this.sourceHeight = height;

      if(this.rotation == 90 || this.rotation == -90) {
        var tmp = width;
        width = height;
        height = tmp;
      }

      opts.onLoad({width, height, framesMeta, duration});
    }

    if(opts.mimeType == "image/gif") {
      this.seeker = new GifSeeker({...opts, onLoad});
    }
    else if(opts.mimeType.startsWith("video/")) {
      this.seeker = new VideoSeeker({...opts, onLoad});
    }

    this.canvas = document.createElement("canvas");
    this.ctx = this.canvas.getContext("2d");
  }

  extractFrames(width, height, count, callback, errorCallback) {
    this.canvas.width = width;
    this.canvas.height = height;
    
    var frameno = 0;

    var matchesSourceDims = this.sourceWidth == width && this.sourceHeight == height;

    var timecode = 0;

    var onBlob = (frame, blob) => {
      if(blob == null) {
        errorCallback();
        return;
      }
      frameno += 1;
      timecode += frame.delay;
      if(frameno < count) {
        extractCurrent();
      }
      callback({ blob: blob, delay: frame.delay, timecode });
    }

    var rotate = this.rotation != undefined && this.rotation != 0;
    var flipDims = rotate && (this.rotation == 90 || this.rotation == -90);
    if(rotate) {
      this.ctx.translate(width/2, height/2);
      this.ctx.rotate(-this.rotation/180*Math.PI);
    }

    var extractCurrent = () => {
      if(this.aborted) return;
      this.seeker.seekFrame(frameno).then((frame) => {
        if(this.mimeType == "image/gif") {
          if(matchesSourceDims) {
            frame.frame.toBlob((blob) => onBlob(frame, blob), "image/jpeg", 0.92);
          }
          else {
            this.ctx.drawImage(frame.frame, 0, 0, width, height);

            this.canvas.toBlob((blob) => onBlob(frame, blob), "image/jpeg", 0.92);
          }
        }
        else if(this.mimeType.startsWith("video/")) {
          try {
            if(rotate) {
              var w = flipDims ? height : width;
              var h = flipDims ? width : height;
              this.ctx.drawImage(frame.frame, 0, 0, this.sourceWidth, this.sourceHeight, -w/2, -h/2, w, h);
            }
            else {
              this.ctx.drawImage(frame.frame, 0, 0, width, height);
            }
          }
          catch(e) {
            var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            var is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
            // Firefox for Android bug:
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1481406
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1526207
            if(is_firefox && is_android && e && e.name == 'NS_ERROR_NOT_AVAILABLE') {
              errorCallback("Failed to extract frames from video.\n\nIf you are using Firefox on Android, this might be due to an issue with your browser. We are working on finding a solution. In the meantime, please use a different browser or select a GIF instead.");
            }
            else {
              errorCallback(e);
            }
            return;
          }

          this.canvas.toBlob((blob) => onBlob(frame, blob), "image/jpeg", 0.92);
        }

      });
    }

    extractCurrent();
  }

  abort() {
    this.aborted = true;
  }
}