var supportedFormats = null;

function getSupportedFormats() {
  if(supportedFormats == null) {

    const mp4 = {ext: 'mp4', mime: 'video/mp4', mimeCodec: 'video/mp4; codecs="avc1.42E01E"'}; // H264 in MP4
    const mov = {ext: 'mov', mime: 'video/quicktime', mimeCodec: 'video/quicktime; codecs="avc1.42E01E"'}; // H264 in MOV

    const allFormats = [
      mp4,
      mov,
      {ext: 'webm', mime: 'video/webm', mimeCodec: 'video/webm; codecs="vp8, vorbis"'}, // VP8
      {ext: 'ogg', mime: 'video/ogg', mimeCodec: 'video/ogg; codecs="theora"'},
      {ext: 'ogg', mime: 'audio/ogg', mimeCodec: 'video/ogg; codecs="theora"'},  // Videos CAN have audio/ogg mimetype
      {ext: 'ogv', mime: 'video/ogg', mimeCodec: 'video/ogg; codecs="theora"'},

      // brew: looks like avi is just not supported by any browser anymore so why bother
    ];
    const video = document.createElement('video');
    supportedFormats = allFormats.filter((format) => video.canPlayType(format.mimeCodec) == "probably");

    if(supportedFormats.indexOf(mp4) >= 0 && supportedFormats.indexOf(mov) < 0) {
      // on chrome it seems that we can just accept MOV files, even if the browser reports it can not handle them
      supportedFormats.push(mov);
    }
    supportedFormats.push({ext: 'gif', mime: 'image/gif'});
  }

  return supportedFormats;
}

export default { getSupportedFormats };
