import loadImage from './load_image.js';

export default class FrameArrayLayer {
  constructor(opts) {
    this.src = opts.src;
    this.width = opts.width;
    this.height = opts.height;
  }

  // eslint-disable-next-line no-unused-vars
  renderFrameTo(frameno, canvas, ctx) {
    return loadImage(this.src[frameno].image).then((image) => {
      ctx.drawImage(image, 0, 0);
    })
  }
}
