import Cookies from 'js-cookie';

var Cookie = {
  set(name, klass, value, options) {
    if(!Cookie.consentGivenFor(klass)) return

    Cookies.set(name, value, options)
  },

  get(name) {
    return Cookies.get(name)
  },

  setRemoteConsent(value) {
    let decodedValue = null
    try {
      decodedValue = JSON.parse(Buffer.from(value, "base64").toString("ascii"))
    } catch(e) {
      return
    }

    if(Cookie.consentManaged()) {
     if(this.consentCookie().timestamp >= decodedValue.timestamp) {
       return
     }
    }

    Cookie.set("consent", "essential", value, { expires: 365, secure: true, sameSite: "None" })
  },

  consentCookie() {
    let cookie = Cookies.get("consent")
    if(cookie == undefined) return null

    let decodedValue = null
    try {
      decodedValue = JSON.parse(Buffer.from(cookie, "base64").toString("ascii"))
    } catch(e) {
      return
    }

    return decodedValue
  },

  consentManaged() {
    let cookie = Cookie.consentCookie()
    if(cookie == null) return false
    let consent = cookie.consent

    if(!consent.hasOwnProperty("managed")) return false

    return consent.managed == true
  },

  consentGivenFor(klass) {
    if(klass === "essential") return true
    if(!Cookie.consentManaged()) return false

    let cookie = Cookie.consentCookie().consent
    if(!cookie.hasOwnProperty(klass)) return false

    return cookie[klass] === true
  },

  consentGivenForVersion(version) {
    if(!Cookie.consentManaged()) return false
    let cookie = Cookie.consentCookie().consent

    return cookie[cookiePolicyVersion] === version
  },

  resetConsent() {
    return Cookies.remove("consent")
  }
}

export default Cookie
