import $ from 'jquery';

import loadPaddle from './paddle';

$(function() {
  var tabs = $("#dashboard-tabs");
  if(tabs.length > 0) {
    var url = document.location.toString();
    if (url.match('#')) {
      $('.nav-card-tabs a').removeClass("active");
      $('.nav-card-tabs a[href="#'+url.split('#')[1]+'"]').tab('show').addClass("active");
    }

    $('.nav-card-tabs a').on('shown.bs.tab', function (e) {
      if(history.pushState) {
        history.pushState(null, null, e.target.hash);
      } else {
        window.location.hash = e.target.hash;
      }
    })
  }

  $(".update-subscription-btn, .cancel-subscription-btn").click(function(e) {
    e.preventDefault();
    loadPaddle().then(() => {
      Paddle.Checkout.open({
        override: $(this).data("url"),
      });
      e.preventDefault();
    })
  });

  $(".show-more-video-transactions-link").click((e) => {
    e.preventDefault();
    $(".video-transaction-hidden").removeClass("d-none");
    $(".show-more-video-transactions-link").hide();
  })
});