import Vue from 'vue/dist/vue.esm'

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

//import VueTippy, { TippyComponent } from "vue-tippy";
//Vue.use(VueTippy);
import Tippy from '../components/pro/tippy';
Vue.component("tippy", Tippy);

import $ from 'jquery';

const context = require.context("../components", true, /\.vue$/)

$(() => {
  var vueComponents = $(".vue-component");
  for(var i = 0; i < vueComponents.length; i++) {
    var tag = $(vueComponents[i]);
    var filename = tag.data("vue-file");
    var classRef = context(filename).default;
    var props = tag.data("vue-props") || {};
    var on = tag.data("vue-on") || {};

    var boundOn = {};
    Object.keys(on).map(function(key, index) {
      var functionName = on[key];
      boundOn[key] = function() {
        window[functionName].apply(this, arguments);
      }
    });

    // translations
    if("t" in props) {
      props.t = JSON.parse(props.t);
    }

    var vue = new Vue({
      el: tag[0],
      render: h => h(classRef, {
        props: props,
        on: boundOn,
      }),
    })
  }
});
