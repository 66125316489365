<template>
  <div class="mt-2">
    <div class="text-center pt-2">
      <div v-if="rating == null">
        <div style="display: inline-block; font-size: 80%; vertical-align: middle; margin-right: 5px;">
          Rate this result:
        </div>
        <div style="display: inline-block; vertical-align: middle;">
          <a href="#" class="rate-btn" @click.prevent="rateGood" title="Rate good">
            <i class="fal fa-smile fa-2x"></i>
          </a>
          <a href="#" class="rate-btn" @click.prevent="rateBad" title="Rate bad">
            <i class="fal fa-frown fa-2x"></i>
          </a>
        </div>
      </div>
      <div v-if="rating == 'good'">
        <div style="margin-bottom: 5px; font-size: 80%;">Spread the word <i class="fal fa-bullhorn"></i></div>

        <a target="_blank" class="btn btn-outline-light btn-sm" href="https://twitter.com/intent/tweet?text=I%20just%20removed%20a%20video%20background%20with%20unscreen%20&amp;via=unscreencom">
          <i class="fab fa-twitter fa-fw"></i> Tweet
        </a>
        <a class="btn btn-outline-light btn-sm" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.unscreen.com" target="_blank">
          <i class="fab fa-facebook-f fa-fw"></i> Share
        </a>
      </div>
      <div v-if="rating == 'bad'">
        <div style="font-size: 80%;">Thanks for your feedback!</div>
      </div>
    </div>

    <modal @close="consentModal = false" v-if="consentModal" :mobileFullWidth="true">
      <div class="mx-auto px-4 py-3" style="background: #535353; border-radius: 5px; max-width: 580px;">
        <h2 style="font-size: 2.3rem; margin-top: 1rem; margin-bottom: 1rem;">
          Thanks for your feedback!
        </h2>
        <p class="lead">
          Want to help us improve? <span style="opacity: 0.5;">(optional)</span>
        </p>
        <ul style="list-style-type: none; padding-left: 0px;">
          <li>
            <i class="fas fa-arrow-right" style="margin-right: 5px;"></i>
            Contribute this video &amp; help us make unscreen better
          </li>
          <li>
            <i class="fas fa-arrow-right" style="margin-right: 5px;"></i>
            Teach the Artificial Intelligence
          </li>
          <li>
            <i class="fas fa-arrow-right" style="margin-right: 5px;"></i>
            Get better results for similar videos in the future
          </li>
        </ul>
        <div style="font-size: 80%; margin-top: 15px;">
          <label style="font-weight: normal;" :class="termsClass">
            <input type="checkbox" v-model="termsAccepted">
            I agree to the <a style="color: inherit; text-decoration: underline;" target="_blank" href="/ipc">Improvement Program Conditions</a>.*
          </label>
          <label style="font-weight: normal;">
            <input type="checkbox" v-model="rememberDecision">
            Remember my decision for future videos that I rate negatively
          </label>
        </div>
        <div class="row" style="text-align: center;">
          <div class="col-md">
            <button class="btn btn-outline-primary" @click="consentAccept" style="margin-top: 15px;">
              Yes, contribute video
            </button>
            <div style="font-size: 75%; opacity: 0.75; margin-top: 3px;">
              Choose if you want us to use this video for quality improvements.
            </div>
          </div>
          <div class="col-md">
            <button class="btn btn-outline-primary" @click="consentReject" style="margin-top: 15px;">
              No, don't contribute
            </button>
            <div style="font-size: 75%; opacity: 0.75; margin-top: 3px;">
              Choose if you do NOT want us to use this video for quality improvements.
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from './modal';
import improvedecision from '../../src/improvedecision';
import { getConfig } from '../../src/get_meta';

import JSZip from 'jszip';
import $ from 'jquery';

export default {
  props: {
    getFrames: Function,
    improveToken: String,
  },
  data: () => {
    return {
      rating: null,
      consentModal: false,
      termsAccepted: false,
      rememberDecision: false,
      termsClass: '',
    }
  },
  components: {
    Modal
  },
  methods: {
    rateGood() {
      this.rating = 'good';
    },
    rateBad() {
      this.rating = 'bad';
      this.checkImprovementPossible();
    },
    checkImprovementPossible() {
      if(!this.improveToken) return;

      var decision = improvedecision.get();
      if(decision == undefined) {
        this.consentModal = true;
      }
      else if(decision == 'accept') {
        this.submitForImprovement();
      }
    },
    consentAccept() {
      if(!this.termsAccepted) {
        this.termsClass = 'text-danger';
        return;
      }
      if(this.rememberDecision) {
        improvedecision.set('accept');
      }
      this.consentModal = false;
      this.submitForImprovement();
    },
    consentReject() {
      if(this.rememberDecision) {
        improvedecision.set('reject');
      }
      this.consentModal = false;
    },
    submitForImprovement() {
      var frames = this.getFrames();

      var zip = new JSZip();
      for(var i = 0; i < frames.length; i++) {
        zip.file(`frame-${String(i).padStart(8, '0')}.jpg`, frames[i]);
      }

      zip.generateAsync({ type:"blob" }).then((blob) => {
        var fd = new FormData();
        fd.append('token', this.improveToken);
        fd.append('frames', blob);

        $.ajax({
          url: getConfig()['improve_api_url'],
          method: "POST",
          data: fd,
          processData: false,
          contentType: false
        });
      });

    },
  }
}
</script>

<style scoped>
.rate-btn {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  min-height: 32px;
  min-width: 32px;
}
.rate-btn:hover {
  opacity: 0.8;
}
</style>
