var form = null;
var input = null;

import FileTypes from './file_types';

import $ from 'jquery';

export function pickFile(opts={}) {
  return new Promise((resolve, reject) => {
    if(!form) {
      form = $("<form style='display: none;'/>");
      form.appendTo($("body"));
    }
    if(input) {
      input.remove();
    }

    input = $(`
      <input name="video[original]" type="file">
    `);

    var accept = null;

    if(opts.accept) {
      accept = opts.accept;
    }
    else {
      var exts = FileTypes.getSupportedFormats();

      exts = exts.map((format) => `.${format.ext}`);

      if(opts.additionalTypes) {
        exts = exts.concat(opts.additionalTypes);
      }

      accept = exts.join(",");
    }

    input.attr("accept", accept);

    input.appendTo(form);

    input.on("change", function() {
      if(this.files.length == 0) return;
      resolve(this.files[0]);
    });

    input[0].click();
  });
};
