<template>
  <div class="container" :style="{'max-width': containerWidth}">
    <div class="video mt-3 mb-5">
      <div style="background: #202020;" class="px-3 px-md-4 py-2 text-muted">
        <div class="row">
          <div class="col-md">
            <span class="d-inline-block mr-2">{{ filename }}</span>
            <span class="d-inline-block small mr-1" v-if="remote && remote.duration && remote.framerate">
              <i class="fas fa-clock"></i>
              {{ formatTimecode(remote.duration) }}
              <span class="d-none d-md-inline">minutes,</span>
              <span class="d-inline d-md-none">min,</span>
              <span>{{ remote.framerate }} fps</span>
            </span>
          </div>
          <div class="col-md-4 text-md-right">
            <span v-if="remote.status == 'done'" class="small text-muted">
              This video will be deleted {{ storedUntilText }}
            </span>

            <a href="#" style="text-decoration: underline;" @click.prevent="destroy" class="small text-muted">
              <span v-if="screen == 'upload'">cancel</span>
              <span v-else-if="remote.status == 'confirming' || remote.status == 'error'">discard</span>
              <span v-else-if="remote.status == 'done' ">delete now</span>
            </a>
          </div>
        </div>
      </div>
      <div class="container-fluid position-relative">
        <div class="px-md-2 py-2 pt-md-3">
          <div v-if="screen == 'upload'">
            <div v-if="remote.status == 'error'">
              <div class="py-3">
                <div class="alert alert-danger">
                  {{ remote.error_detail }}
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="!file && remote.status == 'uploading'">
                Uploading from other browser or device, or upload failed.
              </div>
              <div v-else>
                <h3 class="mb-3">Uploading...</h3>
                <progress-bar :value="uploadProgress*100" :message="uploadMessage" :cancellable="true" @cancel="destroy" />

                <div class="small text-muted text-center mt-3">
                  <notification-toggle />
                </div>
              </div>
              </div>
          </div>
          <div v-else>
            <div v-if="remote.status == 'error'">
              <div class="pb-3">
                <div class="alert alert-danger">
                  {{ remote.error_detail }}
                </div>
              </div>
            </div>
            <div v-if="screen == 'preview' || screen == 'adjust'">
              <div class="row">
                <div class="col-md-9">
                  <div v-if="screen == 'preview'">
                    <h3>
                      Preview Frames
                    </h3>
                    <p class="small text-muted">
                      Check the preview to assess the quality of the full video before processing it. You can change the background if you like.
                    </p>
                  </div>
                  <div v-if="screen == 'adjust'">
                    <h3>
                      Adjust Settings
                    </h3>
                    <p class="small text-muted">
                      Select the background of your choice. You can choose the output format in the next step.
                    </p>
                  </div>
                  <editor ref="editor" :width="remote.result_width" :height="remote.result_height" :original="false" :sidebyside="remote.status == 'confirming' || remote.status == 'processing'" @change="editorBg = $event.bg;" :initialBg="initialEditorBg">
                    <template v-slot:side>
                      <img v-if="remote.status == 'confirming' || remote.status == 'processing'" v-for="(frame, i) in remote.preview_frames" :src="frame.original_url" :class="{'img-fluid': true, 'd-none': previewFrameIndex != i}" ondragstart="return false;">
                    </template>
                    <template v-slot:foreground>
                      <transparent-video v-if="remote.status == 'done' || remote.status == 'reprocessing'" :composite_video_url="remote.composite_video_url" />
                      <img v-if="remote.status == 'confirming' || remote.status == 'processing'" v-for="(frame, i) in remote.preview_frames" :src="frame.result_url" :class="{'img-fluid': true, 'd-none': previewFrameIndex != i}" ondragstart="return false;">
                    </template>
                    <template v-slot:below>
                      <div v-if="remote.status == 'confirming' || remote.status == 'processing'" class="preview-segments d-flex align-items-center mt-md-2">
                        <button class="btn btn-outline-secondary play-btn p-0 btn-sm" @click="togglePreviewPlay">
                          <i class="fas fa-pause" v-if="previewPlaying"></i>
                          <i class="fas fa-play" v-else></i>
                        </button>
                        <div :class="{'preview-segment flex-grow-1 py-4': true, 'active': i == previewFrameIndex}" v-for="(previewFrame, i) in remote.preview_frames" @click="previewPause(); previewFrameIndex = i;">
                          <div class="preview-segment-label" v-if="i == previewFrameIndex">
                            {{ formatTimecode(previewFrame.timecode) }}
                          </div>
                          <div class="preview-segment-inner"></div>
                        </div>
                      </div>
                    </template>
                  </editor>
                </div>
                <div class="col-md-3 pt-md-5">
                  <div v-if="remote.status == 'confirming'">
                    <div class="small font-weight-bold my-1 mt-md-4">
                      <span v-if="remote.confirmable">Length</span>
                      <span v-else>Price</span>
                    </div>
                    <div class="font-weight-bold my-1">
                      <span v-if="remote.confirmable">{{ formatTimecode(remote.duration) }} min</span>
                      <span v-else>{{ formatPrice(remote.payg.currency, remote.payg.price_times_hundred) }}</span>
                    </div>
                    <div style="font-size: 65%" v-if="remote.framerate_multiplier > 1" class="my-1 text-muted">
                      {{ remote.framerate }} fps
                      <i class="fas fa-arrow-right"></i>
                      billed as {{ formatTimecode(1000 * remote.framerate_multiplier * Math.ceil(remote.duration / 1000)) }} min
                      <videoHighFpsHint :fps="remote.framerate" />
                    </div>
                    <div v-if="remote.confirmable">
                      <button class="btn btn-primary w-100 my-1" @click="chooseFormat">
                        Next
                      </button>
                    </div>
                    <div v-else>
                      <button v-if="remote.payg.waiting_for_payment" class="btn btn-primary w-100 my-1" :disabled="true">
                        Waiting for payment...
                      </button>
                      <div v-else>
                        <div style="max-width: 220px;">
                          <button class="btn btn-primary w-100 my-1" @click="showCheckout">
                            Buy Now
                          </button>
                          <div class="mt-3 mb-1" style="font-size: 65%;">
                            or <strong>save up to 80%</strong>
                            with a subscription starting at {{ formatPrice(remote.payg.currency, remote.payg.subscription_start_price_times_hundred) }} / month
                          </div>
                          <div class="mt-2 mb-1">
                            <button class="btn btn-outline-primary w-100" @click="pricingOverlay = true;">
                              Show Plans
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="remote.promo_available" class="mt-3 mb-1 p-2 text-center" style="background: #87e3c2; color: #454545; font-size: 80%; border-radius: 3px;">
                      <div class="mb-2">
                        <div><i class="fas fa-gift fa-2x"></i></div>
                        <span>{{ remote.promo_description }}</span>
                      </div>
                      <button class="btn btn-outline-secondary w-100" @click="chooseFormat({promo: true})">Process free</button>
                    </div>
                  </div>
                  <div v-if="screen == 'adjust'">
                    <div class="mt-md-5 pt-md-5">
                      <div class="alert alert-warning" v-if="adjustmentsLeft <= 2">
                        <strong>Note:</strong> You can adjust the settings for this video {{ adjustmentsLeft }} more time{{ adjustmentsLeft == 1 ? '' : 's' }}.
                      </div>

                      <div class="row" style="max-width: 250px;">
                        <div class="col pr-1">
                          <button class="btn btn-outline-primary w-100" @click="adjustSettings = false;">Back</button>
                        </div>
                        <div class="col pl-1">
                          <button class="btn btn-primary w-100" @click="chooseFormat" :disabled="adjustmentsLeft <= 0">Next</button>
                        </div>
                      </div>
                      <div class="text-muted small font-weight-bold mt-3 mt-md-4 mb-2">
                        Ready to download
                      </div>
                      <variant-list :variants="[...remote.variants, null]" @select="selectVariant($event.variant); adjustSettings = false;" :variantBackgroundText="variantBackgroundText" :variantFormatText="variantFormatText" :variantThumbnail="variantThumbnail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="screen == 'result'">
              <div class="row align-items-center">
                <div class="offset-md-2 col-md-4 text-center">
                  <div v-if="selectedVariant">
                    <div v-if="selectedVariant.format == 'gif'">
                      <img :src="selectedVariant.download_url" alt="" class="img-fluid transparency-grid mx-auto" />
                    </div>
                    <div v-if="selectedVariant.format == 'mp4'">
                      <video :width="remote.result_width" :height="remote.result_height" muted="muted" loop="loop" autoplay="autoplay" playsinline="playsinline" controls="controls" style="max-width: 100%; height: auto;" class="mx-auto">
                        <source :src="selectedVariant.download_url" type="video/mp4">
                      </video>
                    </div>
                  </div>
                  <div v-else>
                    <div class="transparency-grid d-inline-block">
                      <transparent-video :composite_video_url="remote.composite_video_url" />
                    </div>
                    <plugin-overlay v-if="includesProBundle" :t="t"/>
                  </div>
                </div>
                <div class="col-md-6 py-2 pl-md-5">
                  <h6 style="font-size: 0.8rem;">
                    {{ variantBackgroundText(selectedVariant) }}
                  </h6>
                  <h5 style="font-size: 1.1rem;">
                    {{ variantFormatText(selectedVariant) }}
                  </h5>
                  <div class="text-muted small">
                    {{ filesize(selectedVariant ? selectedVariant.filesize : remote.pro_bundle_filesize) }}
                  </div>
                  <div>
                    <a :href="selectedVariant ? selectedVariant.download_url : remote.pro_bundle_url" target="_blank" rel="noopener" :download="selectedVariant ? selectedVariant.filename : remote.pro_bundle_filename" class="btn btn-primary mt-3" style="min-width: 170px">
                      Download
                    </a>
                  </div>
                  <div class="mt-2" v-if="remote.variants.length > 0">
                    <div class="text-muted small font-weight-bold mt-3 mb-2">
                      Also ready to download
                    </div>
                    <variant-list :variants="[...remote.variants, null].filter((variant) => variant != selectedVariant)" @select="selectVariant($event.variant)" :variantBackgroundText="variantBackgroundText" :variantFormatText="variantFormatText" :variantThumbnail="variantThumbnail" />
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="offset-md-2 col-6 col-md-4">
                  <div class="text-md-center">
                    <a href="#" @click.prevent="adjustSettings = true;" class="text-muted small py-2 d-inline-block" style="text-decoration: none;" v-if="adjustmentsLeft > 0">
                      <i class="fas fa-chevron-down"></i>
                      Adjust Settings
                    </a>
                    <span v-if="adjustmentsLeft <= 0" class="text-muted small py-2">
                      No adjustments left
                      <tippy :content="`Videos can be adjusted up to ${this.remote.max_variants_count} times only.`">
                        <i class="fas fa-info-circle"></i>
                      </tippy>
                    </span>
                  </div>
                </div>
                <div class="col-6 text-right">
                  <a :href="feedbackLink" class="text-muted small py-2 d-inline-block" style="text-decoration: underline;">Provide Feedback</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="remote.status == 'processing' || remote.status == 'reprocessing' || creatingVariant" class="video-overlay d-flex align-items-center justify-content-center">
          <div class="position-relative w-100 p-3" style="max-width: 500px; max-height: 100%; background-color: #323232;">
            <h3 class="mb-3">
              Processing...
            </h3>

            <progress-bar :value="creatingVariant ? createVariantProgress * 0.1 : (10 + remote.processing_progress * 0.9)" :message="processingMessage" :cancellable="false" />

            <div class="small text-muted text-center mt-3">
              <notification-toggle />
            </div>
          </div>
        </div>
      </div>
      <div v-if="pricingOverlay" class="page-overlay d-flex align-items-center justify-content-center">
        <div class="position-relative w-100 overlay-inner" style="max-width: 1025px;">
          <a href="#" @click.prevent="pricingOverlay = false;" class="d-flex align-items-center justify-content-center overlay-close-btn">
            <i class="fas fa-times fa-2x"></i>
          </a>
          <div style="background: #535353; overflow: auto; max-height: 100%;" class="p-3 mx-auto w-100">
            <pricing v-bind="remote.payg.subscription_pricing_properties" :payg_video="{filename, price_times_hundred: remote.payg.price_times_hundred}" @payg-checkout="showCheckout" @subscribed="afterSubscribe" />
          </div>
        </div>
      </div>
      <div v-if="formatOverlay" class="page-overlay d-flex align-items-center justify-content-center">
        <div class="position-relative w-100 overlay-inner" style="max-width: 960px;">
          <a href="#" @click.prevent="formatOverlay = false;" class="d-flex align-items-center justify-content-center overlay-close-btn">
            <i class="fas fa-times fa-2x"></i>
          </a>
          <div style="background: #535353; overflow: auto; max-height: 100%;" class="p-3 mx-auto w-100">
            <h3 class="text-center mt-2 mb-3">Select Output Format</h3>
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-4 d-flex flex-column mb-3 mb-md-0">
                  <div class="output-format-block flex-grow-1 d-flex flex-column">
                    <div style="background: #202020;" class="p-3 d-flex align-items-center">
                      <div class="mr-3"><img :src="t.pro.image_urls['icon_video.svg']" alt="" style="max-height: 52px;"></div>
                      <div class="flex-grow-1">
                        <div class="font-weight-bold mb-1 h6">Video (MP4)</div>
                        <div style="font-size: 90%;">Best for sharing</div>
                      </div>
                    </div>
                    <div style="background: #323232;" class="p-3 pt-1 flex-grow-1 d-flex flex-column">
                      <div class="ml-1 flex-grow-1" style="font-size: 90%;">
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> High quality</div>
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Sound</div>
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> No software needed</div>
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div style="font-size: 65%; margin-top: -8px;" class="mb-1 text-muted">
                        <span class="mr-1">Recommended for</span>

                        <tippy content="<p><strong>Recommended for all videos with exchanged backgrounds</strong>. Works with all video publishing platforms and social media.</p>">
                          <i class="fas fa-info-circle"></i>
                        </tippy>
                      </div>
                      <div>
                        <img :src="t.pro.image_urls['integration_logos/youtube.png']" title="Youtube" alt="Youtube" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/facebook.png']" title="Facebook" alt="Facebook" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/instagram.png']" title="Instagram" alt="Instagram" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/twitter.png']" title="Twitter" alt="Twitter" class="img-fluid integration-logo mr-2 my-2">
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div class="text-center">
                        <button :class="['btn process-btn w-75', (editorBg.type == 'transparent' ? 'btn-secondary' : 'btn-primary')]" :disabled="editorBg.type == 'transparent' || confirming" @click="confirm({format: 'mp4'})">Process Video</button>
                        <div class="mt-2" style="font-size: 65%; min-height: 30px;">
                          <div v-if="editorBg.type == 'transparent'">
                            Not available with transparent background
                            <a href="#" @click.prevent="formatOverlay = false" style="color: inherit; text-decoration: underline;" class="d-inline-block">change background</a>
                          </div>
                          <div v-else>
                            Estimated processing time:<br/>
                            {{ formatWaitTime((remote.status == 'done' ? 0 : remote.estimated_processing_time) + remote.estimated_compositing_time) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex flex-column mb-3 mb-md-0">
                  <div class="output-format-block flex-grow-1 d-flex flex-column">
                    <div style="background: #202020;" class="p-3 d-flex align-items-center">
                      <div class="mr-3"><img :src="t.pro.image_urls['icon_pro_bundle.svg']" alt="" style="max-height: 52px;"></div>
                      <div class="flex-grow-1">
                        <div class="font-weight-bold mb-1 h6">Pro Bundle</div>
                        <div style="font-size: 90%;">Best for editing</div>
                      </div>
                    </div>
                    <div style="background: #323232;" class="p-3 pt-1 flex-grow-1 d-flex flex-column">
                      <div class="ml-1 flex-grow-1" style="font-size: 90%;">
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Best quality</div>
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Sound</div>
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Alpha Matte</div>
                        <div class="my-1 my-md-2 small feature-subline">Editing software needed</div>
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div style="font-size: 65%; margin-top: -8px;" class="mb-1 text-muted">
                        <span class="mr-1">Recommended for</span>

                        <tippy content="<p><strong>Recommended for further editing in other software</strong>. Works with most popular video editing software.</p>">
                          <i class="fas fa-info-circle"></i>
                        </tippy>
                      </div>
                      <div>
                        <img :src="t.pro.image_urls['integration_logos/davinci_resolve.png']" title="Davinci Resolve" alt="Davinci Resolve" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/adobe_after_effects.png']" title="Adobe After Effects" alt="Adobe After Effects" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/adobe_premiere.png']" title="Adobe Premiere Pro" alt="Adobe Premiere Pro" class="img-fluid integration-logo mr-2 my-2">
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div class="text-center">
                        <button :class="['btn process-btn w-75', (editorBg.type != 'transparent' ? 'btn-secondary' : 'btn-primary')]" :disabled="editorBg.type != 'transparent' || confirming" @click="confirm({format: 'pro_bundle'})">Process Pro Bundle</button>
                        <div class="mt-2" style="font-size: 65%; min-height: 30px;">
                          <div v-if="editorBg.type != 'transparent'">
                            Not available with {{ editorBg.type }} background
                            <a href="#" @click.prevent="$refs.editor.selectTransparent()" style="color: inherit; text-decoration: underline;" class="d-inline-block">use transparent background</a>
                          </div>
                          <div v-else>
                            Estimated processing time:<br/>
                            {{ formatWaitTime(remote.status == 'done' ? 0 : remote.estimated_processing_time) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex flex-column mb-3 mb-md-0">
                  <div class="output-format-block flex-grow-1 d-flex flex-column">
                    <div style="background: #202020;" class="p-3 d-flex align-items-center">
                      <div class="mr-3"><img :src="t.pro.image_urls['icon_animated_gif.svg']" alt="" style="max-height: 52px;"></div>
                      <div class="flex-grow-1">
                        <div class="font-weight-bold mb-1 h6">Animated GIF</div>
                        <div style="font-size: 90%;">Best for short animations</div>
                      </div>
                    </div>
                    <div style="background: #323232;" class="p-3 pt-1 flex-grow-1 d-flex flex-column">
                      <div class="ml-1 flex-grow-1" style="font-size: 90%;">
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Ready to use</div>
                        <div class="my-1 my-md-2"><i class="fas fa-check mx-2 mr-md-3"></i> Autoplay</div>
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div style="font-size: 65%; margin-top: -8px;" class="mb-1 text-muted">
                        <span class="mr-1">Recommended for</span>

                        <tippy content="<p><strong>Recommended for stickers and animated overlays</strong>. Limited quality and length.</p>">
                          <i class="fas fa-info-circle"></i>
                        </tippy>
                      </div>
                      <div>
                        <img :src="t.pro.image_urls['integration_logos/giphy.png']" title="GIPHY" alt="GIPHY" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/microsoft_powerpoint.png']" title="Microsoft Powerpoint" alt="Microsoft Powerpoint" class="img-fluid integration-logo mr-2 my-2">
                        <img :src="t.pro.image_urls['integration_logos/google_slides.png']" title="Google Slides" alt="Google Slides" class="img-fluid integration-logo mr-2 my-2">
                      </div>
                      <div><hr style="opacity: 0.5;"></div>
                      <div class="text-center">
                        <button :class="['btn process-btn w-75', (remote.duration > gifMaxDuration ? 'btn-secondary' : 'btn-primary')]" :disabled="remote.duration > gifMaxDuration || confirming" @click="confirm({format: 'gif'})">Process GIF</button>
                        <div class="mt-2" style="font-size: 65%; min-height: 30px;">
                          <div v-if="remote.duration > gifMaxDuration">
                            Not available for videos over {{ gifMaxDuration / 1000 }} seconds
                          </div>
                          <div v-else>
                            Estimated processing time:<br/>
                            {{ formatWaitTime((remote.status == 'done' ? 0 : remote.estimated_processing_time) + remote.estimated_compositing_time) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3" style="font-size: 65%;">
                Not sure what to pick? All settings can be adjusted later on.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Rails from "@rails/ujs"

import ChunkedUpload from '../../core/chunked_upload';
import PreventUnload from '../../src/prevent_unload';
import formatTimecode from '../../src/timecode';
import formatPrice from '../../src/price';
import Notifications from '../../src/notifications';
import loadPaddle from '../../src/paddle';

import ProgressBar from './progress_bar';
import NotificationToggle from './notification_toggle';
import Pricing from './pricing';
import VideoHighFpsHint from './video_high_fps_hint';
import TransparentVideo from './transparent_video';
import VariantList from './variant_list';
import Editor from '../clips/editor';
import PluginOverlay from './plugin_overlay';

export default {
  props: {
    file: File,
    initialRemote: Object,
    selected: Boolean,
    t: Object,
  },
  data() {
    return {
      chunkedUpload: null,
      uploadProgress: 0,
      remote: this.initialRemote,
      canClosePage: true,
      pollTimeout: null,

      editorBg: {},
      previewFrameIndex: 0,
      previewPlaying: false,
      previewPlayInterval: null,
      formatOverlay: false,
      gifMaxDuration: 20000,
      usePromo: false,

      confirming: false,
      pricingOverlay: false,
      adjustSettings: false,
      creatingVariant: false,
      createVariantProgress: 0,
    }
  },
  mounted() {
    if(this.file) {
      this.upload();
      this.scrollTo();
    }
    if(this.remote.status == 'preparing') {
      this.uploadProgress = 0.7;
      this.waitForPreview();
    }
    if(this.remote.status == 'confirming') {
      this.previewPlay();
    }
    this.watchRemote();
    if(this.selected) {
      this.scrollTo();
    }
  },
  computed: {
    adjustmentsLeft() {
      return this.remote.max_variants_count - this.remote.variants.length;
    },
    initialEditorBg() {
      var variant = this.selectedVariant;
      if(variant) {
        switch(variant.background_type) {
        case 'color':
          return {
            type: 'color',
            value: `#${variant.background_color}`
          };
        case 'image_url':
        case 'image_file':
          return {
            type: 'image',
            url: variant.background_image_url,
            source: {
              type: 'image',
              image: { image_hd_url: variant.background_image_url }
            }
          };
        case 'video_url':
        case 'video_file':
          return {
            type: 'video',
            url: variant.background_video_url,
            source: {
              type: 'video',
              video: { video_hd_url: variant.background_video_url }
            }
          };
        }
      }
      return {type: 'transparent'};
    },
    containerWidth() {
      if(this.screen == 'upload') {
        return '500px';
      }
      else {
        return '1300px';
      }
    },
    filename() {
      if(this.remote.filename) {
        return this.remote.filename;
      }
      else if(this.file) {
        return this.file.name
      }
      else {
        return "<unknown-file>";
      }
    },
    filenameWithoutExtension() {
      var filename = this.filename;
      return filename.replace(/\..+$/, '');
    },
    feedbackLink() {
      const subject = `My Video: ${this.filename}`;
      return `/support?subject=${encodeURIComponent(subject)}`;
    },
    includesProBundle() {
      if(this.remote.pro_bundle_url != null) {
        return true;
      }
      return false;
    },
    screen() {
      if(!this.remote.id || this.remote.status == 'uploading' || this.remote.status == 'preparing') return "upload";
      if(this.remote.status == 'confirming' || this.remote.status == 'processing') return "preview";
      if(this.remote.status == 'done' && !this.adjustSettings) return "result";
      if((this.remote.status == 'done' && this.adjustSettings) || this.remote.status == 'reprocessing') return "adjust";
      return null;
    },
    selectedVariant() {
      if(!this.remote || !this.remote.variants) return null;
      for(var i = 0; i < this.remote.variants.length; i++) {
        var variant = this.remote.variants[i];
        if(variant.selected) return variant;
      }
      return null;
    },
    uploadMessage() {
      var msg = [];
      if(this.file && !this.remote.id) msg.push("Preparing upload...");
      if(this.file && this.remote.status == 'uploading') msg.push("Uploading...");
      if(this.remote.status == 'preparing') msg.push("Generating preview...");
      if(!this.canClosePage) msg.push("(please don't close this page)");
      return msg.join(' ');
    },
    processingMessage() {
      var msg = [];
      if(this.remote.status == 'confirming' || this.remote.status == 'done') msg.push("Uploading...");
      else if(this.remote.pro_bundle_url) msg.push("Rendering...");
      else msg.push("Removing background...");
      if(!this.canClosePage) msg.push("(please don't close this page)");
      return msg.join(' ');
    },
    storedUntilText() {
      var date = new Date(this.remote.stored_until);
      var now = new Date();
      var days = Math.round((date - now)/(86400*1000));
      if(days <= 1) {
        return "soon";
      }
      else {
        return `in ${days} days`;
      }
    },
    destroyNeedsConfirmation() {
      if(this.screen == 'upload' && this.remote.status == 'error') return false
      return true
    }
  },
  watch: {
    remote(newRemote, oldRemote) {
      this.watchRemote();
      if((oldRemote.status == 'uploading' || oldRemote.status == 'preparing') && newRemote.status == 'confirming') {
        Notifications.show(`${this.filename} preview ready`);
        if(window.hj) window.hj('trigger', 'pro_preview_ready');
        this.previewPlay();
      }
      if((oldRemote.status == 'processing' || oldRemote.status == 'reprocessing') && newRemote.status == 'done') {
        this.adjustSettings = false;
        Notifications.show(`${this.filename} download ready`);
        if(window.hj) window.hj('trigger', 'pro_download_ready');
      }
    },
  },
  components: { Editor, ProgressBar, NotificationToggle, Pricing, VideoHighFpsHint, TransparentVideo, VariantList, PluginOverlay },
  methods: {
    formatTimecode,
    formatPrice,
    variantBackgroundText(variant) {
      if(variant == null) return "Transparent Background";

      switch(variant.background_type) {
        case "transparent":
          return "Transparent background";
        case "color":
          return "Colored background";
        case "image_url":
        case "image_file":
          return "Image background";
        case "video_url":
        case "video_file":
          return "Video background";
      }
    },
    variantFormatText(variant) {
      if(variant == null) return "Pro Bundle";

      switch(variant.format) {
        case "gif":
          return "Animated GIF";
        case "mp4":
          return "Video (MP4)";
      }
    },
    variantThumbnail(variant) {
      if(variant == null) return this.remote.thumbnail_image_url;
      else return variant.thumbnail_image_url;
    },
    selectVariant(variant) {
      var id = variant == null ? null : variant.id;
      for(var i = 0; i < this.remote.variants.length; i++) {
        var variant = this.remote.variants[i];
        variant.selected = variant.id == id;
      }
      // we could save this selection?
    },
    filesize(bytes) {
      var mb = bytes/(1024*1024);
      mb = Math.round(mb * 10) / 10;
      return `${mb} MB`;
    },
    togglePreviewPlay() {
      if(this.previewPlaying) this.previewPause();
      else this.previewPlay();
    },
    previewPlay() {
      if(this.previewPlaying) return;
      this.previewPlaying = true;
      this.previewPlayInterval = setInterval(() => {
        if(this.remote.status != 'confirming') {
          this.previewPause();
          return;
        }
        this.previewFrameIndex = (this.previewFrameIndex + 1) % this.remote.preview_frames.length;
      }, 1000);
    },
    previewPause() {
      if(!this.previewPlaying) return;
      this.previewPlaying = false;
      if(this.previewPlayInterval) {
        clearInterval(this.previewPlayInterval);
        this.previewPlayInterval = null;
      }
    },
    chooseFormat(opts={}) {
      this.usePromo = opts.promo;
      this.formatOverlay = true;
    },
    scrollTo() {
      var offset = this.$el.offsetTop;
      $("html, body").animate({scrollTop: offset-120});
    },
    formatWaitTime(ms) {
      var s = ms / 1000;
      if(s <= 5) return "a few seconds";
      if(s <= 10) return "10 seconds";
      if(s <= 20) return "20 seconds";
      if(s <= 30) return "30 seconds";
      var m = Math.round(s / 60);
      if(m <= 1) return "1 minute";
      if(m <= 10) return `${m} minutes`;
      return `${Math.round(m / 5) * 5} minutes`;
    },
    forbidLeavePage() {
      if(!this.canClosePage) return;
      this.canClosePage = false;
      PreventUnload.add();
    },
    permitLeavePage() {
      if(this.canClosePage) return;
      this.canClosePage = true;
      PreventUnload.remove();
    },
    afterSubscribe() {
      this.pricingOverlay = false;
      Paddle.Spinner.show()

      // wait a bit to give the webhook a chance to confirm the payment already
      var delay = 3000;
      setTimeout(() => {
        $.ajax({
          url: `/videos/${this.remote.id}`,
          method: "GET"
        }).then((data) => {
          Paddle.Spinner.hide();
          this.remote = data.data;
          if(this.remote.confirmable) this.chooseFormat(); // automatically open format dialog
        }).catch((e) => {
          Paddle.Spinner.hide();
          console.log(e);
        })
      }, delay);
    },
    showCheckout() {
      loadPaddle().then(() => {
        Paddle.Checkout.open({
          override: this.remote.payg.checkout_url,
          successCallback: () => {
            window.track('Purchase', 'product_purchased', 'Product purchased');

            this.pricingOverlay = false;

            Paddle.Spinner.show()

            // wait a bit to give the webhook a chance to confirm the payment already
            var delay = 2000;
            setTimeout(() => {
              $.ajax({
                url: `/videos/${this.remote.id}`,
                method: "PATCH",
                data: {
                  payg_checkout_completed: true,
                },
                headers: {
                  'X-CSRF-Token': Rails.csrfToken()
                }
              }).then((data) => {
                Paddle.Spinner.hide();
                this.remote = data.data;
                if(this.remote.confirmable) this.chooseFormat(); // automatically open format dialog
              }).catch((e) => {
                Paddle.Spinner.hide();
                console.log(e);
              })
            }, delay);
          },
          allowQuantity: false,
          disableLogout: true,
          locale: 'en',
          displayModeTheme: 'dark',
        });
      });
    },
    destroy() {
      let confirmed = false
      if(this.destroyNeedsConfirmation) {
        confirmed = confirm(`Are you sure you want to delete ${this.filename}?\nDeleted videos can NOT be restored.`)
      } else {
        confirmed = true
      }

      if(!confirmed) return

      if(this.remote.id) {
        $.ajax({
          url: `/videos/${this.remote.id}`,
          method: "DELETE",
          headers: {
            'X-CSRF-Token': Rails.csrfToken()
          }
        }).then((data) => {
          if(this.chunkedUpload) {
            this.chunkedUpload.cancel();
            this.chunkedUpload = null;
          }
          this.permitLeavePage();
          this.$emit("deleted", { id: this.$vnode.key });
        }).catch((jqXHR) => {
          if(jqXHR.status == 404) {
            this.permitLeavePage();
            this.$emit("deleted", { id: this.$vnode.key });
          }
          else {
            console.log(e);
          }
        })
      }
      else {
        this.permitLeavePage();
        this.$emit("deleted", { id: this.$vnode.key });
      }
    },
    confirm(opts={}) {
      this.confirming = true;
      if(opts.format == 'pro_bundle') {
        this.finalizeConfirm().finally(() => {
          this.formatOverlay = false;
          this.confirming = false;
        })
      }
      else {
        this.formatOverlay = false;

        this.creatingVariant = true;
        this.createVariant(opts).then((variant) => {
          this.finalizeConfirm({ variant_id: variant.id }).finally(() => {
            this.confirming = false;
            this.creatingVariant = false;
          });
        }).catch((error) => {
          this.confirming = false;
          this.creatingVariant = false;
          console.log(error);
        });
      }
    },
    createVariant(opts) {
      this.createVariantProgress = 0;
      return new Promise((resolve, reject) => {
        var data = {
          format: opts.format,
        };
        var bgFile = null;
        var uploadUrlField = null;
        if(this.editorBg.type == 'transparent') {
          data.background_type = 'transparent';
        }
        else if(this.editorBg.type == 'color') {
          data.background_type = 'color';
          data.background_color = this.editorBg.value;
        }
        else if(this.editorBg.type == 'image') {
          if(this.editorBg.source.type == 'image') {
            data.background_type = 'image_url';
            data.background_image_url = this.editorBg.source.image.image_hd_url;
          }
          else if(this.editorBg.source.type == 'gif') {
            data.background_type = 'image_url';
            data.background_image_url = this.editorBg.source.gif.full_gif;
          }
          else if(this.editorBg.source.type == 'file') {
            bgFile = this.editorBg.source.file;
            uploadUrlField = 'background_image_file_upload_url';
            data.background_type = 'image_file';
            data.background_image_file = {
              filename: bgFile.name,
              filesize: bgFile.size,
              mimetype: bgFile.type,
            };
          }
        }
        else if(this.editorBg.type == 'video') {
          if(this.editorBg.source.type == 'video') {
            data.background_type = 'video_url';
            data.background_video_url = this.editorBg.source.video.video_hd_url;
          }
          else if(this.editorBg.source.type == 'file') {
            bgFile = this.editorBg.source.file;
            uploadUrlField = 'background_video_file_upload_url';
            data.background_type = 'video_file';
            data.background_video_file = {
              filename: bgFile.name,
              filesize: bgFile.size,
              mimetype: bgFile.type,
            };
          }
        }

        $.ajax({
          url: `/videos/${this.remote.id}/variants`,
          method: "POST",
          data: {
            variant: data
          },
          headers: {
            'X-CSRF-Token': Rails.csrfToken()
          }
        }).then((data) => {
          var variant = data.data;
          if(variant.status == 'ready') {
            resolve(variant);
          }
          else if(variant.status == 'uploading') {
            this.forbidLeavePage();
            this.chunkedUpload = new ChunkedUpload({
              file: bgFile,
              url: variant[uploadUrlField],
              onProgress: (info) => {
                this.createVariantProgress = 100 * info.progress;
              },
              onComplete: () => {
                this.chunkedUpload = null;
                $.ajax({
                  url: `/videos/${this.remote.id}/variants/${variant.id}`,
                  method: "PATCH",
                  data: {
                    upload_finished: true,
                  },
                  headers: {
                    'X-CSRF-Token': Rails.csrfToken()
                  }
                }).then((data) => {
                  this.permitLeavePage();
                  this.createVariantProgress = 100;
                  resolve(variant);
                }).catch((e) => {
                  reject(e);
                })
              },
              onFail: (message) => {
                this.permitLeavePage();
                reject(message);
              }
            });

            this.chunkedUpload.start();
          }
        }).catch((e) => {
          reject(e);
        })
      })
    },
    finalizeConfirm(opts={}) {
      return new Promise((resolve, reject) => {
        var data = {
          video: {
            selected_variant_id: opts.variant_id,
          }
        };
        if(this.remote.status == 'confirming') {
          data.confirm = true;
          data.confirm_source = (this.usePromo ? 'promo' : null);
        }
        else if(this.remote.status == 'done') {
          data.reprocess = true;
        }
        $.ajax({
          url: `/videos/${this.remote.id}`,
          method: "PATCH",
          data: data,
          headers: {
            'X-CSRF-Token': Rails.csrfToken()
          }
        }).then((data) => {
          this.remote = data.data;
          resolve();
        }).catch((e) => {
          console.log(e);
          reject();
        })
      })
    },
    watchRemote() {
      if(this.pollTimeout) clearTimeout(this.pollTimeout);
      if(this.remote.next_poll_in) this.pollTimeout = setTimeout(() => this.poll(), this.remote.next_poll_in);
    },
    poll() {
      $.ajax({
        url: `/videos/${this.remote.id}`,
        method: "GET",
      }).then((data) => {
        this.remote = data.data;
      }).catch((e) => {
        console.log(e);
      })
    },
    waitForPreview() {
      var i = 2;
      var prepareProgressInterval = setInterval(() => {
        var factor = 70; // higher = slower progress
        var previewProgress = Math.log(i) / Math.log(Math.max(factor, i*2));
        this.uploadProgress = 0.7 + 0.3 * previewProgress;
        i++;
      }, 500);
    },
    upload() {
      this.forbidLeavePage();

      $.ajax({
        url: '/videos',
        method: "POST",
        data: {
          video: {
            filename: this.file.name,
            source_attributes: {
              filename: this.file.name,
              filesize: this.file.size,
              mimetype: this.file.type,
            }
          }
        },
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        }
      }).done((data) => {
        this.remote = data.data;

        this.chunkedUpload = new ChunkedUpload({
          file: this.file,
          url: this.remote.upload_url,
          onProgress: (info) => {
            this.uploadProgress = info.progress * 0.7;
          },
          onComplete: () => {
            this.chunkedUpload = null;
            $.ajax({
              url: `/videos/${this.remote.id}`,
              method: "PATCH",
              data: {
                upload_finished: true,
              },
              headers: {
                'X-CSRF-Token': Rails.csrfToken()
              }
            }).then((data) => {
              this.permitLeavePage();

              this.remote = data.data;
              this.waitForPreview();

            }).catch((e) => {
              console.log(e);
            })
          },
          onFail: (message) => {
            this.permitLeavePage();
            console.log('Upload failed!', e)
          }
        });

        this.chunkedUpload.start();
      }).catch((jqXHR) => {
        this.permitLeavePage();
        if(jqXHR.status == 422) {
          this.remote = {
            status: 'error',
            error_detail: jqXHR.responseJSON.errors[0].title,
          }
        }
        else {
          this.remote = {
            status: 'error',
            error_detail: jqXHR.statusText,
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.video {
  background: #323232;
}

hr {
  border-top-color: rgba(118, 128, 137, 0.5);
}

.preview-segment {
  cursor: pointer;
  position: relative;
}
.preview-segment-inner {
  background: #D9D9D9;
  height: 10px;
  border-right: 1px solid #323232;
}
.preview-segment.active {
  cursor: default;
}
.preview-segment.active .preview-segment-inner {
  background: #2699FB;
}
.preview-segments .preview-segment:first-child .preview-segment-inner {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.preview-segments .preview-segment:last-child .preview-segment-inner {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 0;
}
.preview-segment-label {
  background: #D9D9D9;
  color: #454545;
  font-size: 65%;
  position: absolute;
  top: 3px;
  line-height: 1;
  padding: 1px;
  left: 50%;
  z-index: 10;
  margin-left: -14px;
}
.preview-segment-label:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(217, 217, 217, 0);
  border-top-color: #D9D9D9;
  border-width: 4px;
  margin-left: -4px;
}
.play-btn {
  background: #323232;
  color: #D9D9D9;
  border-color: #D9D9D9;
  border-radius: 8px;
  width: 29px;
  height: 29px;
}

.process-btn[disabled] {
  background: #9f9f9f;
}

.video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: rgba(0,0,0,0.8);
}
.page-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  background: rgba(0,0,0,0.8);
}

.integration-logo {
  opacity: 0.5;
  max-height: 26px;
}
.feature-subline {
  margin-left: 2rem;
}
@media(min-width: 768px) {
  .feature-subline {
    margin-left: 2.5rem;
  }
}

.output-format-block {
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3);
  overflow: hidden;
  border-radius: 3px;
}

.overlay-close-btn {
  position: fixed;
  top: 0;
  right: 0;

  background: #D9D9D9;
  border-radius: 50%;
  color: inherit;
  width: 44px;
  height: 44px;
  color: #535353;
  text-decoration: none;
  z-index: 100;
}

@media(min-width: 768px) {
  .overlay-close-btn {
    position: absolute;
    top: -15px;
    right: -15px;
  }
}
.overlay-inner {
  max-height: 100%;
  overflow: auto;
}
@media(min-width: 768px) {
  .overlay-inner {
    overflow: visible;
  }
}

</style>

