export default class LayerComposer {
  constructor(frameFormat) {
    this.layers = [];
    this.primaryLayerIdx = null;
    this.frameFormat = frameFormat;
    this.eventHandlers = {};
  }

  on(event, handler) {
    var handlers = this.eventHandlers[event];
    if(handlers == undefined) {
      handlers = [];
      this.eventHandlers[event] = handlers;
    }
    handlers.push(handler);
  }

  emit(event, ...args) {
    if(this.eventHandlers[event] == undefined) return;
    this.eventHandlers[event].forEach((handler) => {
      handler(...args);
    });
  }

  add(layer) {
    this.layers.push(layer);
    return this.layers.length - 1;
  }

  setPrimary(idx) {
    this.primaryLayerIdx = idx;
  }

  setup() {
    return new Promise((resolve) => {
      this.canvas = document.createElement("canvas");
      this.canvas.width = this.primaryLayer().width;
      this.canvas.height = this.primaryLayer().height;
      this.ctx = this.canvas.getContext("2d");

      resolve();
    });
  }

  primaryLayer() {
    return this.layers[this.primaryLayerIdx];
  }

  process() {
    var frames = [];

    var result = Promise.resolve();

    var totalFrames = this.primaryLayer().src.length;

    this.primaryLayer().src.forEach((primaryFrame, i) => {
      result = result.then(() => {
        return this.processFrame(i, primaryFrame.timecode).then((frame) => {
          this.emit('progress', { percent: 100*i/totalFrames })
          frames.push(frame)
        });
      })
    });

    result = result.then(() => {
      return frames;
    })

    return result;
  }

  processFrame(frameno, timecode) {
    //console.log("PROCESSFRAME", frameno, timecode);

    var result = Promise.resolve();

    result = result.then(() => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    });

    this.layers.forEach((layer, i) => {
      result = result.then(() => {
        if(i == this.primaryLayerIdx) {
          return layer.renderFrameTo(frameno, this.canvas, this.ctx);
        }
        else {
          return layer.renderTimecodeTo(timecode, this.canvas, this.ctx);
        }
      })
    })

    result = result.then(() => {
      switch(this.frameFormat) {
        case "png":
          return this.canvas.toDataURL("image/png");
        case "jpg":
          return this.canvas.toDataURL("image/jpeg", 0.92);
        case "raw":
          return this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
      }
    });

    return result;
  }

  teardown() {
    // TODO
  }
}