<template>
  <span class="hover-tooltip" :data-tippy-content="content" :data-tippy-interactive="interactive" :data-tippy-allowHTML="true">
    <slot></slot>
  </span>
</template>

<script>
import Tooltips from '../../src/tooltips';

export default {
  data() {
    return {
      instance: null
    }
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    interactive: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.instance = Tooltips.create(this.$el);
  },
  beforeDestroy() {
    if(this.instance == null) return;
    Tooltips.destroy(this.instance);
    this.instance = null;
  },
}
</script>
