import VideoSeeker from '../core/video_seeker.js';
import GifSeeker from '../core/gif_seeker.js';
import drawImageProp from './draw_image_prop.js';

export default class VideoLayer {
  constructor(opts) {
    this.url = opts.url;
    this.mimeType = opts.mimeType;

    var seekerOptsFn = (callback) => {
      return {
        url: this.url,
        type: "video",
        onError: (e) => {
          alert(`Error: ${e.message}`);
        },
        onLoad: ({width, height, duration, framesMeta}) => {
          this.width = width;
          this.height = height;
          this.duration = duration;
          this.framesMeta = framesMeta;
          callback();
        }
      }
    }

    this.seekerPromise = new Promise((resolve) => {
      var seekerOpts = seekerOptsFn(() => resolve(seeker));
      if(this.mimeType == "image/gif") {
        var seeker = new GifSeeker(seekerOpts);
      }
      else {
        var seeker = new VideoSeeker(seekerOpts);
      }
    })

    this.frames = [];
  }

  renderTimecodeTo(timecode, canvas, ctx) {
    return this.seekerPromise.then((seeker) => {
      var loopedTimecode = timecode % this.duration;

      return seeker.seekTimecode(loopedTimecode).then((video) => {
        drawImageProp(ctx, video.frame, this.width, this.height, 0, 0, canvas.width, canvas.height, 0.5, 0.5);
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  renderFrameTo(frameno, canvas, ctx) {
    return this.seekerPromise.then((seeker) => {
      var loopedFrameno = frameno % this.framesMeta.length;

      return seeker.seekFrame(loopedFrameno).then((video) => {
        drawImageProp(ctx, video.frame, this.width, this.height, 0, 0, canvas.width, canvas.height, 0.5, 0.5);
      });
    });
  }
}
