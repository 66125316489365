<template>
  <div style="max-width: 250px;">
    <a v-for="variant in variants" href="#" class="d-block my-1" @click.prevent="$emit('select', { variant })">
      <div class="d-flex align-items-center">
        <div class="pr-2">
          <div class="position-relative" style="width: 40px; border-radius: 3px; overflow: hidden;">
            <div style="width: 100%; padding-top: 100%;" class="transparency-grid"></div>
            <div class="variant-thumbnail" :style="{'background-image': `url('${variantThumbnail(variant)}')`}"></div>
          </div>
        </div>
        <div class="flex-grow-1 small">
          <div>{{ variantBackgroundText(variant) }}</div>
          <div>{{ variantFormatText(variant) }}</div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    variants: Array,
    variantBackgroundText: Function,
    variantFormatText: Function,
    variantThumbnail: Function,
  },
}
</script>

<style scoped>
.variant-thumbnail {
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>

