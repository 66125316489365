<template>
  <div>
    <transition name="fade">
      <div v-if="show" class="d-none d-lg-flex fixed-bottom mb-5 justify-content-center text-dark" ondragstart="return false;">
        <div class="overlay rounded-lg py-3 px-4 position-relative" style="width: 600px;">
          <div class="">
            <div class="row">
              <div class="col-3 p-0 d-flex">
                <img :src="t.pro.image_urls['integration_logos/adobe_after_effects.svg']" title="Adobe After Effects" alt="Adobe After Effects" class="img-fluid overlay-integration-logo mx-2 my-2">
                <img :src="t.pro.image_urls['integration_logos/adobe_premiere.svg']" title="Adobe Premiere Pro" alt="Adobe Premiere Pro" class="img-fluid overlay-integration-logo my-2">
              </div>
              <div class="col p-0 text-left">
                <div class="mr-4">
                  <div><strong>Using Premiere Pro or After Effects?</strong></div>
                  <div>Import the Pro Bundle easily using our plugin.</div>
                  <div class="mt-1 d-flex align-items-end">
                    <strong>
                    <a href="https://exchange.adobe.com/creativecloud.details.105746.html" target="_blank">
                      Get plugin
                      <i class="link pl-1 fas fa-chevron-right"></i>
                    </a>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <i class="close fas fa-times position-absolute" @click="close"></i>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Cookie from "../../src/cookie.js";

export default {
  props: {
    t: Object,
  },

  data() {
    return {
      closedByUser: false,
      cookieMaxAge: 180, // days
      cookieName: "improved_flow_promo"
    }
  },

  computed: {
    show() {
      if(this.closedByUser) return false
      if(this.cookieExists) return false
      return true
    },

    cookieExists() {
      return Cookie.get(this.cookieName)  === "closed"
    }
  },

  methods: {
    close() {
      this.closedByUser = true
      this.setCookie()
    },

    setCookie() {
      Cookie.set(this.cookieName, "functional","closed", { expires: this.cookieMaxAge });
    }
  }
}
</script>

<style lang="scss" scoped>

.overlay-integration-logo {
  max-height: 50px;
}

.overlay {
  font-size: 0.9rem;
  background-color: #F7F9FA;
  max-width: 700px;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, .25) !important
}

.close {
  font-size: 1.1rem;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

i.link {
  color: #2699FB;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
