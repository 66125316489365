function formatPrice(currency, price_times_hundred) {
  switch(currency) {
    case "usd":
      return `$ ${(price_times_hundred/100).toFixed(2)}`;
    case "eur":
      return `€ ${(price_times_hundred/100).toFixed(2)}`;
    case "gbp":
      return `£ ${(price_times_hundred/100).toFixed(2)}`;
    case "inr":
      return `₹ ${(price_times_hundred/100).toFixed(0)}`;
    case "cad":
      return `C$ ${(price_times_hundred/100).toFixed(2)}`;
    case "aud":
      return `A$ ${(price_times_hundred/100).toFixed(2)}`;
    case "rub":
      return `RUB ${(price_times_hundred/100).toFixed(0)}`;
    case "mxn":
      return `MX$ ${(price_times_hundred/100).toFixed(0)}`;
    default:
      return '?';
  }
}

export default formatPrice;
