import $ from 'jquery';

import { showUploadPage, uploadFile, uploadUrl, uploadGif } from './upload';
import { getUploaderTranslations } from './get_meta';

function fileDropped(e) {
  e.preventDefault()

  var dataTransfer = e.originalEvent.dataTransfer;

  for(var item of dataTransfer.files) {
    uploadFile(item);
    return;
  }

  if(dataTransfer) {
    var htmlData = dataTransfer.getData('text/html');
    if(htmlData) {
      var url = htmlData.match(/src\s*=\s*"(.+?)"/);
      if(!url) {
        url = htmlData.match(/href\s*=\s*"(.+?)"/);
      }
      if(url) {
        var htmlEntitiesDecodedUrl = $('<textarea />').text(url[1]).text();
        uploadUrl(decodeURI(htmlEntitiesDecodedUrl));
      }
    }
  }
}

import { pickFile } from '../src/pick_file';
import isMobileOrTablet from '../src/is_mobile_or_tablet';

$(() => {
  window.onUploadUrl = (url) => {
    var url = prompt("Video URL:");
    uploadUrl(url);
  }
  window.onUploadFile = (url) => {
    showUploadPage('free');
    var pickOpts = {};
    if(isMobileOrTablet()) pickOpts.accept = 'video/*';
    pickFile(pickOpts).then((file) => {
      uploadFile(file)
    });
  }

  if($(".dropzone-enabled").length > 0) {
    var t = getUploaderTranslations();
    var dropzone = $(`
      <div class="file-dropzone" style="display: none;">
        <h1>
          ${t.drop_video_anywhere}
        </h1>
        <p>
          (${t.one_at_a_time})
        </p>
      </div>
    `).appendTo($("body"));

    function showDropzone() {
      if(!dropzone) return;
      dropzone.show();
    }

    function hideDropzone() {
      if(!dropzone) return;
      dropzone.hide();
    }

    dropzone.click(function(e) {
      hideDropzone();
    });
    dropzone.on("dragleave", function(e) {
      hideDropzone();
    });
    $("body").on("dragenter", function(e) {
      e.stopPropagation();
      e.preventDefault();
      showDropzone();
    });
    $("body").on("dragover", function(e) {
      e.stopPropagation();
      e.preventDefault();
      showDropzone();
    });
    $("body").on("drop", function(e) {
      hideDropzone();
      fileDropped(e);
    });
    $('body').bind('paste', function(e) {
      var target = $(e.originalEvent.target);
      if(target.is("input, textarea")) return;
      if(!e.originalEvent.clipboardData) return;
      var items = e.originalEvent.clipboardData.items;
      if(!items) return;

      for (var i = 0; i < items.length; i++) {
        var type = items[i].type;
        if(type.indexOf("image/gif") == -1 && type.indexOf("video/") == -1) continue;
        var blob = items[i].getAsFile();

        uploadFile(blob);
        return;
      }

      for(var i = 0; i < items.length; i++) {
        if(items[i].type.indexOf("text/plain") == -1) continue;
        items[i].getAsString((url) => {
          uploadUrl(url);
        });
        return;
      }
    });
  }
});
