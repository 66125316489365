<template>
  <div style="overflow: hidden; padding-top: 16px;">
    <div class="container" style="max-width: 990px;">
      <div class="row">
        <div class="col-md pricing-col d-flex flex-column mb-3">
          <div
            style="transform: rotate(45deg); color: #fff; font-weight: bold; text-align: center; font-size: 18px; line-height: 1; position: absolute; right: -32px; top: 20px;">
            <div style="float: left; width: 0; height: 0;  border-left: 26px solid transparent; border-right: 26px solid transparent;  border-bottom:26px solid #ff7272; margin-right: -26px; position: relative; z-index: 15;
            "></div>
            <div
              style="float: left; background: #ff7272; width: 108px; padding: 3px 0 3px 0; height: 26px; position: relative; z-index: 20;">
              Best Value
            </div>
            <div style="float: left; width: 0;  height: 0;  border-left: 26px solid transparent; border-right: 26px solid transparent;  border-bottom:26px solid #ff7272; margin-left: -26px; position: relative; z-index: 15;
            "></div>
            <div style="position: absolute; left: 0; top: 26px; z-index: 10; width: 0;  height: 0;  border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid #a54c4c;
            "></div>
            <div
              style="position: absolute; right: 0; top: 26px; z-index: 10; width: 0;  height: 0;  border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid #a54c4c;"></div>
          </div>
          <div class="pricing-col-inner flex-grow-1 d-flex flex-column">
            <div class="flex-grow-1 py-2 px-md-1 px-xl-4">
              <h2 class="h3 pricing-col-heading text-center my-3">
                Subscription Plan
              </h2>
              <pricing-minute-heading :price="priceHeading" billing="per video minute" />
              <div class="container-fluid mb-3">
                <a class="row plan py-2" href="#" @click.prevent="selectPlan(plan)" :key="plan.id" v-for="plan in plans">
                  <div class="col-6 col-lg-3 pr-lg-0">
                    <span :class="selected_plan == plan ? 'option checked' : 'option unchecked'"></span>
                    <strong>{{ plan.name }}</strong>
                  </div>
                  <div class="col-6 col-lg-3 text-right">
                    {{ formatPrice(currency, plan.recurring_price_times_hundred) }}
                  </div>
                  <div class="col-12 col-lg-6 text-lg-right pl-lg-0">
                   <small>{{ formatIncludedTime(plan.video_seconds) }} / month =
                      <strong>{{ formatPricePerMinute(plan.recurring_price_times_hundred / plan.video_seconds) }} /
                        min</strong></small>
                  </div>
                </a>
                <a class="row plan py-2" href="#" @click.prevent="selectCustomPlan()">
                  <div class="col-12">
                    <span :class="selected_custom_plan ? 'option checked' : 'option unchecked'"></span>
                    <strong>Custom </strong><small>– Contact us</small>
                  </div>
                </a>
              </div>
            </div>
            <div style="min-height: 102px;">
              <div v-if="!selected_custom_plan">
                <div class="text-center mb-4">
                  <button class="btn btn-primary btn-lg" style="min-width: 190px;"
                        @click="checkSubscription(selected_plan)"
                        :disabled="subscribed_plan_id && subscribed_plan_id == selected_plan.id">
                    {{ subscribeLabel }}
                  </button>
                </div>
                <div class="small text-muted text-center mb-2">
                  {{ formatPrice(currency, selected_plan.recurring_price_times_hundred) }} per month for {{ formatIncludedTime(selected_plan.video_seconds) }} of video cutout <br> price incl. VAT if applicable
                </div>
              </div>
              <div v-if="selected_custom_plan">
                <div class="text-center mb-4">
                  <a :href="enterprise_contact_url" target="_blank" class="btn btn-lg btn-outline-primary">Contact us</a>
                </div>
                <div class="small text-muted text-center mb-2">&nbsp;<br>&nbsp;</div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center px-4 py-2"
                 style="min-height: 72px; background: #323232; border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;">
              <div class="text-center text-muted small">
                <div>
                  <tippy
                    content="<strong>Full refunds within 14 days:</strong> if you are unsatisfied with the results. Limited to 3 minutes of video.">
                    <strong>Risk free:</strong> 14 Days Money Back Guarantee
                    <i class="fas fa-info-circle"></i>
                  </tippy>
                </div>
                <div>
                  <tippy
                    content="<strong>No long-term obligation:</strong> All plans can be cancelled or upgraded/downgraded any time.">
                    <strong>Flexible:</strong> Downgrade, upgrade or cancel any time
                    <i class="fas fa-info-circle"></i>
                  </tippy>
                </div>
                <div>
                  <strong>Exact:</strong> We bill per second. Upload as many videos as you want within the monthly video
                  time budget.
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-muted mt-3" style="font-size: 70%;">
            <pricingFpsHelp/>
          </div>
        </div>
        <div class="col-md pricing-col d-flex flex-column mb-3">
          <div class="pricing-col-inner flex-grow-1 d-flex flex-column">
            <div class="flex-grow-1 py-2 px-md-1 px-xl-4">
              <h2 class="h3 pricing-col-heading text-center my-3">
                Pay as you go
              </h2>
               <pricing-minute-heading :price="formatPricePerMinute(payg.price_per_minute_times_hundred / 60)" :billing="`per video minute`" />
              <div v-if="payg_video" class="text-center">
                <div class="h4 mt-5" style="font-family: inherit;">
                  {{ payg_video.filename }}
                </div>
                <div>
                  <strong>Price for this video: {{ formatPrice(currency, payg_video.price_times_hundred) }}</strong>
                </div>
              </div>
              <div class="text-center" v-else>
                <div class="mt-4 pt-2 mb-2">
                  <i class="fas fa-cloud-upload-alt fa-8x" style="color: #323232;"></i>
                </div>
              </div>
            </div>
            <div style="min-height: 102px;">
              <div class="text-center mb-4">
                <button v-if="payg_video" class="btn btn-outline-primary btn-lg" style="min-width: 190px;"
                        @click="$emit('payg-checkout')">
                  Buy now
                </button>
                <a v-else href="/upload/pro" class="btn btn-outline-primary btn-lg" style="min-width: 190px;">
                  <i class="fas fa-upload"></i>
                  Upload File
                </a>
              </div>
              <div class="small text-muted text-center mb-2 mt-2">
                Upload video to calculate price.<br>minimum
                {{ formatPrice(currency, payg.minimum_price_per_video_times_hundred) }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center px-4 py-2"
                 style="min-height: 92px; background: #323232; border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;">
              <div class="text-center text-muted small d-">
                <div>
                  <tippy
                    content="<strong>Maximum flexibility:</strong> Pay only for what you use and buy each video individually with a length-based price.">
                    <strong>No commitment:</strong> Purchase each video individually
                    <i class="fas fa-info-circle"></i>
                  </tippy>
                </div>
                <div>
                  <strong>Exact:</strong> We bill per second.
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-muted mt-3" style="font-size: 70%;">
            <pricingFpsHelp/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingMinuteHeading from "./pricing_minute_heading";
import PricingFpsHelp from "./pricing_fps_help";

import loadPaddle from "../../src/paddle";
import formatPrice from "../../src/price";

import $ from "jquery";

export default {
  components: { PricingMinuteHeading, PricingFpsHelp },
  data() {
    return {
      selected_plan: this.plans.find((plan) => plan.id == this.initial_selected_plan_id),
      subscribed_plan: this.subscribed_plan_id ? this.plans.find((plan) => plan.id == this.subscribed_plan_id) : null,
      selected_custom_plan: false,
    };
  },
  props: {
    signed_in: Boolean,
    email: String,
    login_url: String,
    initial_selected_plan_id: String,
    billing_profile_id: String,
    subscribed_plan_id: String,
    plans: Array,
    payg: Object,
    payg_video: Object,
    currency: String,
    enterprise_contact_url: String,
  },
  mounted() {
    if (this.signed_in) {
      // preload to make available fast when it's needer later
      loadPaddle();
    }
  },
  computed: {
    subscribeLabel() {
      if (this.subscribed_plan_id) {
        if (this.subscribed_plan) {
          if (this.subscribed_plan.id == this.selected_plan.id) {
            return "Current Plan";
          } else if (this.selected_plan.video_seconds > this.subscribed_plan.video_seconds) {
            return "Upgrade";
          } else {
            return "Downgrade";
          }
        } else {
          return "Change Plan";
        }
      } else {
        return "Subscribe";
      }
    },

    priceHeading() {
      if(this.selected_custom_plan) {
        return "Custom price";
      }
      else {
        return this.formatPricePerMinute(this.selected_plan.recurring_price_times_hundred / this.selected_plan.video_seconds);
      }
    }
  },
  methods: {
    checkSubscription(selected_plan) {
      this.canChangePlan(selected_plan.id).then(() => {
          this.checkoutPlan(selected_plan);
        }
      );
    },

    selectPlan(plan) {
      this.selected_custom_plan = false;
      this.selected_plan = plan;
    },

    selectCustomPlan() {
      this.selected_custom_plan = true;
      this.selected_plan = null;
    },



    canChangePlan(selected_plan_id) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/pricing/can_change_plan/${selected_plan_id}`,
          method: "GET",
          dataType: "json"
        }).then((response) => {
          if (!response.result) {
            console.log(response);
            location.href = "/pricing";
            return;
          }
          resolve();
        });
      });
    },
    checkoutPlan(plan) {
      if (!this.signed_in) {
        location.href = this.login_url;
        return;
      }

      if (this.subscribed_plan_id) {
        var form = $("<form method=\"POST\" />").attr("action", "/pricing/change_plan").attr("style", "position: fixed; left: -9999px; top: -9999px;");
        form.append($("<input type='hidden' name='plan_id' />").attr("value", this.selected_plan.id));
        form.append(
          $("<input type='hidden' />").attr(
            "name", $("meta[name=csrf-param]").attr("content")
          ).attr(
            "value", $("meta[name=csrf-token]").attr("content")
          )
        );
        $("body").append(form);
        form.submit();
        return;
      }
      loadPaddle().then(() => {
        Paddle.Checkout.open({
          product: plan.p_plan_id,
          email: this.email,
          passthrough: JSON.stringify({ billing_profile_id: this.billing_profile_id }),
          allowQuantity: false,
          disableLogout: true,
          locale: "en",
          displayModeTheme: "dark",
          successCallback: () => {
            window.track("Purchase", "plan_purchased", "Plan purchased");
            if (this.payg_video) {
              this.$emit("subscribed");
            } else {
              location.href = `/upload/pro?subscribed=true&p_plan_id=${plan.p_plan_id}`;
            }
          }
        });
      });
    },
    padZero(number) {
      return `${number >= 0 && number <= 9 ? "0" : ""}${number}`;
    },
    formatPrice,
    formatIncludedTime(seconds) {
      var minutes = seconds / 60;
      if (minutes <= 60) {
        return `${this.padZero(Math.floor(minutes))} min`;
      } else {
        var hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
        return `${this.padZero(hours)}:${this.padZero(minutes)} hrs`;
      }
    },
    formatPricePerMinute(price_times_hundred_per_second) {
      return this.formatPrice(this.currency, price_times_hundred_per_second * 60);
    }
  }
};
</script>

<style scoped>
.pricing-col {

}

.pricing-col-inner {
  background: #202020;
  border-radius: 3px;
}

.plan {
  text-decoration: none;
  color: inherit;
}

.pricing-col-heading {
  font-weight: normal;
  font-family: inherit;
}

.option {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 2px solid #D9D9D9;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.option.checked {
  background: #2699FB;
}

.option.unchecked {

}
</style>
