import Cookie from '../src/cookie';

export default function checkQuota(limit) {
  if(limit == undefined || limit == null) return true;

  var today = new Date();
  today.setHours(0,0,0,0);
  var start = Math.floor(today.getTime() / 1000)

  var quota = Cookie.get('quota');
  if(quota) {
    quota = JSON.parse(quota);
    if(quota.start != start) {
      quota = null;
    }
    else {
      if(quota.value < limit) {
        quota.value = quota.value + 1;
      }
      else {
        return false;
      }
    }
  }
  if(!quota) {
    quota = {
      start: start,
      value: 1,
    };
  }
  Cookie.set('quota', 'essential', JSON.stringify(quota), {expires: 2});
  return true;
}
