<template>
  <div class="container">
      <div class="my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_hd.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="A <strong>video resolution of up to 1080p</strong> allows for high-accuracy results and professional use. That's almost 10 times more pixels than the free 360p resolution." data-tippy-allowHTML="true">
            <span class="dotted-dark">Full HD Resolution</span>
          </tippy>
      </div>
      <div class="item my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_full_length.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="<strong>Process videos with unlimited length</strong> for background-free footage of minutes or hours, not just seconds." data-tippy-allowHTML="true">
            <span class="dotted-dark">Full Length Clips</span>
          </tippy>
      </div>
      <div class="my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_no_watermark.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="<strong>Use videos however you like:</strong> No need to mention Unscreen, the result contains no logo." data-tippy-allowHTML="true">
            <span class="dotted-dark">No Watermark</span>
          </tippy>
      </div>
      <div class="my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_pro_video.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="<strong>Download MP4 video files</strong> that you can easily use with your editing software. Framerate settings and audio tracks are preserved." data-tippy-allowHTML="true">
            <span class="dotted-dark">Video File Download</span>
          </tippy>
      </div>
      <div class="my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_api.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="<strong>Make Unscreen Pro part of your daily workflow</strong> to get more work done faster." data-tippy-allowHTML="true">
            <span class="dotted-dark">API + Plugins</span>
          </tippy>
      </div>
      <div class="my-2">
        <div class="d-inline-block text-center mr-4" style="width: 50px;"><img :src="image_urls['icon_sound.svg']" class="img-fluid" /></div>
        <tippy class="hover-tooltip" content="<strong>Enjoy audio support</strong> every time you select Video MP4 or Pro Bundle as output formats. Animated Gif file formats cannot support sound." data-tippy-allowHTML="true">
            <span class="dotted-dark">Audio Support</span>
          </tippy>
      </div>
  </div>
</template>

<script>

export default {
  props: {
    image_urls: Object,
  }
}

</script>
