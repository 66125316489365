import Cookie from '../src/cookie';

var supported = !!("Notification" in window);

var i = 0;
var callbacks = {};

var enabled = supported && (Cookie.get('notifications') == "enabled") && Notification.permission === "granted";

function setEnabled(value) {
  enabled = value;
  Cookie.set('notifications', 'functional', value ? "enabled" : "disabled",  { expires: 365 });
  Object.values(callbacks).forEach((callback) => callback(value));
}

var registered = false;
function registerWorker() {
  if(registered) return;
  registered = true;
  // XXX mobile chrome needs a service worker for notifications to work
  // https://stackoverflow.com/a/31787926
  navigator.serviceWorker.register('/worker-empty.js');
}

function showNotification(...opts) {
  registerWorker();

  navigator.serviceWorker.ready.then(function(registration) {
    registration.showNotification(...opts);
  });
}

function granted() {
  showNotification("Notifications enabled");
  setEnabled(true);
}

function denied() {
  alert("Please enable notifications in your browser settings.");
}

export default {
  subscribe(callback) {
    var id = i++;
    callbacks[id] = callback;
    return id;
  },
  unsubscribe(id) {
    delete callbacks[id];
  },
  supported() {
    return supported;
  },
  enable() {
    if(Notification.permission === "granted") {
      granted();
    }
    else if(Notification.permission === "denied") {
      denied();
    }
    else {
      Notification.requestPermission().then((permission) => {
        if(permission === "granted") {
          granted();
        }
        else if(permission == "denied") {
          denied();
        }
      });
    }

  },
  disable() {
    setEnabled(false);
  },
  get() {
    return enabled;
  },
  show(message) {
    if(supported && enabled) {
      showNotification(message, {
        icon: "https://www.unscreen.com/favicon-128.png"
      });
    }
  }
}
