<template>
  <div>
    <a href="#" v-for="gif in gifs" :key="gif.id" @click.prevent="$emit('select', gif)" class="gif position-relative" ondragstart="return false;">
      <img v-if="format == 'gif'" :src="gif.preview_gif" alt="" class="img-fluid">
      <img v-if="format == 'webp'" :src="gif.preview_webp" alt="" class="img-fluid">
      <!--<video v-if="format == 'mp4'" :src="gif.preview_mp4" :width="gif.preview_width" :height="gif.preview_height" type="video/mp4" autoplay="autoplay" muted="muted" loop="loop" playsinline="playsinline"></video>-->
      <span v-if="gif.attribution.length > 0" class="position-absolute px-1 text-white">by {{ gif.attribution }}</span>
    </a>

  </div>
</template>

<script>

export default {
  props: {
    gifs: Array,
    format: String,
  }
}
</script>

<style scoped>
.gif {
  display: inline-block;
  margin-bottom: 10px;
}
span {
  background-color: rgba(69, 69, 69, 0.5);
  border-top-right-radius: 2px;
  font-size: 8px;
  bottom: 0;
  left: 0;
}
</style>
