import $ from 'jquery';
import Rails from "@rails/ujs"

import { hcaptcha_invisible } from '../src/hcaptcha';

$(() => {
  const optin = $(".newsletter-optin");
  if(optin.length > 0) {
    const email = optin.find(".newsletter-optin--email");
    const form = optin.find(".newsletter-optin--form");
    const success = optin.find(".newsletter-optin--success");
    const successText = optin.find(".newsletter-optin--successText");
    const submit = optin.find(".newsletter-optin--submit");

    optin.submit((e) => {
      e.preventDefault();
      submit.attr("disabled", true);
      hcaptcha_invisible({ sitekey: form.data("hcaptcha-sitekey") }).then((token) => {
        $.ajax({
          type: "POST",
          url: "/newsletter_optins",
          data: {
            "hcaptcha_response": token,
            email: $(email).val()
          },
          headers: {
            'X-CSRF-Token': Rails.csrfToken(),
          },
          success: (data) => {
            submit.attr("disabled", false);
            if(data.status == "ok") {
              $(form).slideUp();
              $(successText).text(data.message);
              $(success).slideDown();
            }
            else {
              alert(data.message);
            }
          },
          error: (e) => {
            submit.attr("disabled", false);
            if(e.status == 422 && e.responseJSON && e.responseJSON.message) {
              alert(e.responseJSON.message);
            }
            else {
              alert("Error: " + e.status + ". Please try again or contact support@unscreen.com if the problem persists.");
            }
          }
        });
      }).catch((error) => {
        submit.attr("disabled", false);
        if(error.event != "close") alert(error.message);
      });
    });
  }
})