import $ from 'jquery';
import Cocoen from '../vendor/cocoen/cocoen';

import "../vendor/cocoen/cocoen.scss";

$(() => {
  $(".cocoen:not(.cocoen-lazy)").each((i, el) => {
    new Cocoen(el);
  })
});
