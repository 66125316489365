const PADDLE_VENDOR_ID = 117820;

var injected = false;
var loaded = false;
var callbacks = [];

function loadPaddle() {
  return new Promise((resolve, reject) => {
    if(loaded) return resolve();
    callbacks.push(resolve);
    if(injected) return;
    injected = true;

    var script = document.createElement('script');
    script.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js')
    script.addEventListener('load', () => {
      loaded = true;

      Paddle.Setup({ vendor: PADDLE_VENDOR_ID });

      for(var i = 0; i < callbacks.length; i++) {
        callbacks[i]();
      }
    });
    script.async = true;
    document.head.appendChild(script);
  })
}

export default loadPaddle;
