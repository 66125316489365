<template>
  <div class="card card-with-shadow card-rounded-max card-without-border upload-widget-card w-100">
    <div class="card-body text-center">
      <div class="mt-4 mb-2 pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="76.883" height="50.663"><path d="M12.141 42.824a4.3 4.3 0 01-4.3-4.3V10.971H4.303a4.3 4.3 0 00-4.3 4.3v31.082a4.3 4.3 0 004.3 4.3h57.312a4.3 4.3 0 004.3-4.3V42.82z" fill="#bac0c4" opacity=".5"/><path d="M72.579 0H15.267a4.3 4.3 0 00-4.3 4.3v31.082a4.3 4.3 0 004.3 4.3h57.312a4.3 4.3 0 004.3-4.3V4.303A4.3 4.3 0 0072.579 0zM38.718 27.12V12.57l15.113 7.275z" fill="#d9d9d9" opacity=".5"/></svg>
      </div>
      <div class="mt-5">
        <button class="btn btn-primary btn-lg" v-on:click="$emit('uploadFile')" style="min-width: 190px;">
          <i class="fas fa-upload"></i>
          Upload Video
        </button>
      </div>
      <div style="font-size: 80%;" class="text-muted mt-4">
        Any length – up to 2 GB<br/>
        .mp4, .mov, .avi, .mpg, .gif, .webm, .ogg
      </div>
    </div>
    <div class="card-footer small text-muted text-center d-none d-md-block">
      {{ t.paste_text_start }}
      <a href="#" class="text-muted select-photo-url-btn" style="text-decoration: underline;" v-on:click.prevent="$emit('uploadUrl')">{{ t.paste_text_url}}</a>
      {{ t.paste_text_end }}

      <span class="d-inline-block px-1 rounded ml-2" style="background: transparent; border: 1px solid #9F9F9F;">{{ modifierKey }}</span> + <span class="d-inline-block px-1 rounded" style="background: transparent; border: 1px solid #9F9F9F;">v</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    t: Object,
    extensions: Array,
  },
  data: () => {
    return {
    }
  },
  computed: {
    modifierKey() {
      var isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
      return isMac ? "cmd" : "ctrl";
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.upload-widget-card {
  background: #202020;

}
.upload-widget-card .card-footer {
  background: #323232;
}
</style>
