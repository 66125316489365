<template>
  <div class="small">
    <span :class="itemClass">
      <i class="fas fa-clock fa-fw"></i>
      <span class="text-muted">{{ formatTimecode(duration) }} minutes, {{ roundedFramerate }} fps</span>
    </span>
    <span :class="itemClass">
      <i class="fas fa-layer-group fa-fw"></i>
      <span class="text-muted">{{ backgroundName }} background</span>
    </span>
    <!--<span :class="itemClass">
      <i class="fas fa-file fa-fw"></i>
      <span class="text-muted">{{ formatName }}</span>
    </span>-->
  </div>
</template>

<script>
import formatTimecode from '../../src/timecode';

export default {
  props: {
    duration: Number,
    backgroundName: String,
    //formatName: String,
    layout: String,
    framerate: Number,
  },
  computed: {
    itemClass() {
      if(this.layout == "vertical") {
        return "process-detail d-block mb-2";
      }
      else {
        return "process-detail d-inline-block mr-2";
      }
    },
    roundedFramerate() {
      return Math.round(this.framerate * 100) / 100;
    }
  },
  methods: {
    formatTimecode
  }
}
</script>

<style scoped>
  .process-detail:last-child {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
</style>

