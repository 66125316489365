<template>
  <div class="my-4">
    <div v-if="signed_in">
      <div v-if="videos.length > 0">
        <div class="mt-2 mb-5 text-center">
          <button class="btn btn-outline-primary btn-lg" @click="pickVideo">
            <i class="fas fa-upload"></i>
            Upload Video
          </button>
        </div>
        <pro-video
          v-for="video in videos"
          :key="video.id"
          :initialRemote="video.remote"
          :file="video.file"
          :selected="selected_video_id == video.id"
          :t="t"
          @deleted="videoDeleted($event.id)"
        />
      </div>
      <div class="empty-state" v-else>
        <h2 class="text-center">
          Upload a video<br/>
          to process it with Unscreen Pro
        </h2>
        <div class="container mt-4 mt-md-5" style="max-width: 800px;">
          <div class="row">
            <div class="col-md">
              <pro-benefits :image_urls="image_urls" />
            </div>
            <div class="col-md-1 d-flex flex-column align-items-center">
              <div class="flex-grow-1" style="width: 1px; background: #768089;"></div>
            </div>
            <div class="col-md d-flex align-items-center justify-content-center">
              <upload-widget v-on:uploadFile="pickVideo" v-on:uploadUrl="$emit('uploadUrl')" v-bind:t="t" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container" style="max-width: 990px;">
        <div class="row empty-state">
          <div class="col-12 col-md-4" style="font-size: 110%;">
            <pro-benefits :image_urls="image_urls" />
          </div>
          <div class="col-12 col-md-8 my-auto pl-md-5" style="border-left: 2px solid #454545;">
            <h1>Sign up for free to use Unscreen Pro.</h1>
            <div class="">
              <a :href=login_url class="btn btn-primary btn-lg mt-md-4" style="position: relative; ">
                Login / Signup
              </a>
              <svg xmlns="http://www.w3.org/2000/svg" width="88.209" height="98.922" viewBox="0 0 88.209 98.922" class="ml-4 p-0 m-0 sprite sprite-arrow" style="color: #93E3C0;transform: scaleX(-1);max-width: 55px;">
                <path d="M79.79,93.774l-2.981,1.17-1.641.6-3.195,1.112c-4.176,1.41-8,2.525-11.282,3.3-6.561,1.548-10.941,1.733-11.585.189s2.569-4.527,8.285-8.1c2.425-1.516,5.322-3.141,8.544-4.8A57.408,57.408,0,0,1,48.179,81.8,60.135,60.135,0,0,1,38.5,75.7,58.62,58.62,0,0,1,29.7,67.015,61.125,61.125,0,0,1,18.809,46.249a68.736,68.736,0,0,1-3.02-18.388A53.086,53.086,0,0,1,15.95,20.8c.086-1.034.147-1.993.287-2.873s.3-1.682.466-2.4c.65-2.878,1.407-4.432,2.244-4.431s1.676,1.547,2.473,4.275c.2.682.4,1.438.589,2.264l.7,2.662c.488,1.9.9,4.068,1.571,6.4a110.967,110.967,0,0,0,5.058,15.825,66.468,66.468,0,0,0,9.768,16.843,60.866,60.866,0,0,0,6.913,7.144,67.012,67.012,0,0,0,7.545,5.506,78,78,0,0,0,14.7,6.927c.152.054.3.1.446.155-2.663-3.208-4.966-6.216-6.789-8.867-3.819-5.554-5.579-9.57-4.372-10.73s5.15.754,10.551,4.788c2.7,2.017,5.765,4.563,9.034,7.52L79.622,74.1l1.268,1.2,2.267,2.26c3.354,3.371,6.86,6.955,10.378,10.614C88.855,90.127,84.209,92.018,79.79,93.774Z" transform="translate(-16.963 0.383) rotate(-7)" fill="currentColor">
                </path>
              </svg>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Video from './video'
import $ from 'jquery'

import { pickFile } from '../../src/pick_file';

import ProBenefits from './pro_benefits';
import UploadWidget from './upload_widget';

export default {
  data() {
    return {
      videos: []
    }
  },
  props: {
    extensions: Array,
    mimetypes: Array,
    max_filesize: Number,
    signed_in: Boolean,
    login_url: String,
    image_urls: Object,
    selected_video_id: String,
    title: String,
    meta_description: String,
    url: String,
    t: Object,
  },
  mounted() {
    if(this.signed_in) {
      $.get('/videos').then((data) => {
        this.videos = [
          ...this.videos,
          ...data.data.map((video) => ({ id: video.id, remote: video }))
        ];
      }).catch((e) => {
        console.log(e);
      })
    }
  },
  components: { ProVideo: Video, ProBenefits, UploadWidget },
  methods: {
    videoDeleted(id) {
      var idx = this.videos.findIndex((video) => video.id == id);
      if(idx == -1) return;
      this.videos.splice(idx, 1);
    },
    pickVideo() {
      pickFile({
        accept: this.extensions.map((ext) => `.${ext}`).join(',')
      }).then((file) => {
        this.filePicked(file);
      })
    },
    urlPicked(opts) {
      var url = opts.url;
      var basename = opts.basename;
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = () => {
        var blob = request.response;

        var filename = basename;

        switch(blob.type) {
        case "image/gif":
          filename += '.gif';
          break;
        case "video/mp4":
          filename += '.mp4';
          break;
        case "video/x-msvideo":
          filename += '.avi';
          break;
        case "video/mpeg":
          filename += '.mpg';
          break;
        case "image/gif":
          filename += '.gif';
          break;
        case "video/quicktime":
          filename += '.mov';
          break;
        case "video/webm":
          filename += '.webm';
          break;
        case "video/ogg":
        case "audio/ogg":
        case "application/ogg":
          filename += '.ogg';
          break;
        }

        var file = new File([blob], filename, { type: blob.type });
        this.filePicked(file);
      };
      request.onerror = () => {
        alert("Failed to load file from URL");
      }
      request.send();
    },
    filePicked(file) {
      var filesize = file.size;

      var filenameRegexp = new RegExp(`\\.${this.extensions.join('|')}$`, 'i');
      if(!file.name.match(filenameRegexp)) {
        alert(`The selected file type is currently not supported.\nSupported files: ${this.extensions.join(', ')}`);
        return
      }

      if(!this.mimetypes.includes(file.type)) {
        alert(`The selected file type is currently not supported.\nSelected type: ${file.type}\nSupported files: ${this.extensions.join(', ')}`);
        return
      }

      if(!file.size || file.size <= 0) {
        alert(`The selected file is empty.`);
        return
      }

      if(file.size > this.max_filesize) {
        alert(`The selected file is too large.\nMaximum file size: ${this.max_filesize/(1024*1024)} MB`);
        return
      }

      window.track('ProVideo', 'pro_upload', 'Pro Upload');
      this.videos.unshift({ id: this.generateKey(), file, remote: {} })
    },
    generateKey() {
      return `${new Date().getTime()}-${Math.round(1000000000*Math.random())}`;
    },
  }
}
</script>

<style scoped>
.empty-state {
  padding: 60px 0;
}
@media(min-height: 800px) { .empty-state { padding: 100px 0 100px 0; } }
@media(min-height: 900px) { .empty-state { padding: 100px 0 170px 0; } }
@media(min-height: 1000px) { .empty-state { padding: 100px 0 220px 0; } }
</style>

