import MP4Box from 'mp4box/dist/mp4box.all';

function boyerMoore(pattern) {
  // Implementation of Boyer-Moore substring search ported from page 772 of
  // Algorithms Fourth Edition (Sedgewick, Wayne)
  // http://algs4.cs.princeton.edu/53substring/BoyerMoore.java.html
  var M = pattern.length;
  if (M === 0) {
    throw new TypeError("pattern must be at least 1 byte long");
  }
  // radix
  var R = 256;
  var rightmost_positions = new Int32Array(R);
  // position of the rightmost occurrence of the byte c in the pattern
  for (var c = 0; c < R; c++) {
    // -1 for bytes not in pattern
    rightmost_positions[c] = -1;
  }
  for (var j = 0; j < M; j++) {
    // rightmost position for bytes in pattern
    rightmost_positions[pattern[j]] = j;
  }
  function boyerMooreSearch(txt, start, end) {
    // Return offset of first match, -1 if no match.
    if (start === undefined) start = 0;
    if (end === undefined) end = txt.length;
    var pat = pattern;
    var right = rightmost_positions;
    var lastIndex = end - pat.length;
    var lastPatIndex = pat.length - 1;
    var skip;
    for (var i = start; i <= lastIndex; i += skip) {
      skip = 0;
      for (var j = lastPatIndex; j >= 0; j--) {
        var c = txt[i + j];
        if (pat[j] !== c) {
          skip = Math.max(1, j - right[c]);
          break;
        }
      }
      if (skip === 0) {
        return i;
      }
    }
    return -1;
  };
  boyerMooreSearch.byteLength = pattern.byteLength;
  return boyerMooreSearch;
}

function toBytesInt32 (num) {
  var arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
  var view = new DataView(arr);
  view.setInt32(0, num, false); // byteOffset = 0; litteEndian = false
  return arr;
}

function conv_fp(x) {
  return x / (1 << 16);
}

function av_display_rotation_get(matrix) {
  var rotation, scale = [];
  scale[0] = Math.hypot(conv_fp(matrix[0]), conv_fp(matrix[3]));
  scale[1] = Math.hypot(conv_fp(matrix[1]), conv_fp(matrix[4]));

  if(scale[0] == 0.0 || scale[1] == 0.0) return NaN;

  rotation = Math.atan2(conv_fp(matrix[1]) / scale[1],
                   conv_fp(matrix[0]) / scale[0]) * 180 / Math.PI;

  return -rotation;
}

export default function applyVideoRotation(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = () => {
      var data = reader.result;
      data.fileStart = 0;

      var mp4boxfile = MP4Box.createFile();

      mp4boxfile.onError = resolve({ fileOrBlob: file, processOpts: {} });
      mp4boxfile.onReady = (info) => {
        if(info.videoTracks.length != 1) {
          reject(`Video track count: ${info.videoTracks.length}`);
          return;
        }
        var matrix = info.videoTracks[0].matrix;

        // get rotation
        var rotation = av_display_rotation_get(matrix);
        if(rotation != NaN) rotation = Math.round(rotation);

        if(rotation == 0) {
          resolve({ fileOrBlob: file, processOpts: {} });
          return;
        }

        // now search the byte sequence that represent this exact rotation again in the video bytes
        var searchPattern = new Uint8Array(matrix.length*4);
        for(var i = 0; i < matrix.length; i++) {
          var uint8 = new Uint8Array(toBytesInt32(matrix[i]));
          searchPattern[i*4] = uint8[0];
          searchPattern[i*4+1] = uint8[1];
          searchPattern[i*4+2] = uint8[2];
          searchPattern[i*4+3] = uint8[3];
        }

        var videoBytes = new Uint8Array(data);

        var search = boyerMoore(searchPattern);
        var skip = search.byteLength;
        var indexes = [];
        for (var i = search(videoBytes); i !== -1; i = search(videoBytes, i + skip)) {
          indexes.push(i);
        }

        // we should find exactly one match
        if(indexes.length != 1) {
          reject(`Could not change view matrix: ${indexes.length} matches found`);
        }

        // uint8 representation of int32 array [65536, 0, 0, 0, 65536, 0, 0, 0, 1073741824]
        // that's a view matrix that does not do any rotations
        var replace = [
          0x00, 0x01, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00,
          0x00,0x00, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00,
          0x00, 0x01, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00,
          0x00, 0x00, 0x00, 0x00,
          0x40, 0x00, 0x00, 0x00
        ];
        for(var i = 0; i < replace.length; i++) {
          videoBytes[indexes[0]+i] = replace[i];
        }

        var blob = new Blob([videoBytes], {type: file.type});
        resolve({ fileOrBlob: blob, processOpts: {rotation: rotation} });
      };

      try {
        mp4boxfile.appendBuffer(data);
        mp4boxfile.flush();
      } catch(e) {
        resolve({ fileOrBlob: file, processOpts: {} });
      }
    };

    reader.readAsArrayBuffer(file);
  })
}
