<template>
  <span>
    <tippy :content="`<strong>This video has a framerate of ${fps} fps.</strong> Videos above 30 fps are billed at a multiple of the base price. <a href='/faq#higher-framerates'>Learn more</a>`" :interactive="true">
      <i class="fas fa-info-circle"></i>
    </tippy>
  </span>
</template>

<script>

export default {
  props: {
    fps: Number,
  }
}
</script>

<style scoped>
</style>
