const gifFrames = require('gif-frames');
//const streamToBlob = require('stream-to-blob');

export default class GifSeeker {
  constructor(opts) {
    this.url = opts.url;

    gifFrames({
      url: this.url,
      frames: 'all',
      outputType: 'canvas',
      cumulative: true,
      //quality: 92,
    }).then((frameData) => {
      this.frames = frameData;

      this.secondsIndex = {};
      var lastSecond = -1;

      var timecode = 0;
      this.framesMeta = this.frames.map((frame, i) => {
        var delay = frame.frameInfo.delay * 10;
        var frameMeta = {
          delay: delay,
          timecode,
        }
        var second = Math.floor(timecode/1000);

        if(second > lastSecond) {
          this.secondsIndex[second] = i;
          lastSecond = second;
        }
        timecode += delay;
        return frameMeta;
      });

      var lastFrame = this.framesMeta[this.frames.length-1];
      opts.onLoad({
        width: this.frames[0].frameInfo.width,
        height: this.frames[0].frameInfo.height,
        duration: lastFrame.timecode + lastFrame.delay,
        framesMeta: this.framesMeta,
      })
    }).catch((e) => {
      var urlRegex = /^https?:\/\/([^\/]+).*$/;
      var urlParts = urlRegex.exec(this.url);
      if(urlParts) {
        opts.onError(`Can not access file at '${urlParts[1]}'. Please verify the URL or try downloading the file to your device, and upload it from there.`);
      }
      else {
        opts.onError(e);
      }
    });
  }

  seekFrame(frameno) {
    return new Promise((resolve) => {
      var frame = this.frames[frameno];
      resolve({ frame: frame.getImage(), delay: frame.frameInfo.delay * 10 });
    });
  }

  seekTimecode(timecode) {
    var second = Math.floor(timecode/1000);
    var startIndex = this.secondsIndex[second];
    if(startIndex == undefined) startIndex = 0;

    var frameIndex = startIndex
    while(frameIndex < this.framesMeta.length) {
      var frame = this.framesMeta[frameIndex];
      if(frame.timecode > timecode) break;
      frameIndex++
    }

    if(frameIndex >= this.framesMeta.length) {
      frameIndex = this.framesMeta.length - 1;
    }

    return this.seekFrame(frameIndex);
  }
}
