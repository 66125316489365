import { Controller } from "stimulus"

import 'daterangepicker/daterangepicker.css'

export default class extends Controller {
  static targets = [ "canvas", "groupBtn", "dateRange" ]

  connect() {
    this.group = $(this.groupBtnTargets).filter(".active").data("value");
    var datevalue = $(this.dateRangeTarget).val().split(" - ");

    import(/* webpackChunkName: "chart" */ 'moment').then(({ default: moment }) => {
      import(/* webpackChunkName: "chart" */ 'daterangepicker/daterangepicker.js').then(({ default: DateRangePicker }) => {
        import(/* webpackChunkName: "chart" */ 'chart.js').then(({ default: Chart }) => {

          this.from = moment(datevalue[0]).format();
          this.to = moment(datevalue[1]).format();

          new DateRangePicker(
            this.dateRangeTarget,
            {
              locale: {
                format: 'YYYY-MM-DD'
              },
            },
            (start, end, label) => {
              this.from = start.format();
              this.to = end.format();
              this.updateData();
            }
          );

          var ctx = this.canvasTarget.getContext('2d');
          this.chart = new Chart(ctx, {
            type: 'line',
            data: {},
            options: {}
          });

          this.updateData()
        });
      });
    });
  }

  changeGroup(e) {
    var btn = $(e.target)
    if (btn.hasClass("translation_missing")) {
      btn = btn.parent();
    }
    $(this.groupBtnTargets).not(btn).removeClass("active")
    btn.addClass("active")
    this.group = btn.data("value")
    this.updateData()
  }

  updateData() {
    $.ajax({
      url: $(this.element).data("url"),
      type: "GET",
      dataType: "json",
      data: {
        group: this.group,
        from: this.from,
        to: this.to,
      },
      success: (data) => {
        this.dataLoaded(data)
      },
      error: (e) => {
        alert("Failed to load chart data.")
      }
    })

  }

  dataLoaded(data) {
    this.chart.data = data.data;
    this.chart.options = data.options;
    this.chart.update();
  }
}
