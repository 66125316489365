const fps = 30;
const step = 1000 / fps;

var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
//var lastTimecode = -1;
//isIos = false;

export default class VideoSeeker {
  constructor(opts) {
    this.url = opts.url;
    this.video = document.createElement("video");
    this.video.crossOrigin = "anonymous";
    this.video.playsInline = "playsInline";
    this.video.muted = "muted";
    
    // iOS safari requires the video to be "visible" somewhere to autoplay it
    // autoplaying is necessary to seek within the video for iOS safari
    //if(isIos) {
      //this.video.autoplay = "autoplay";
      //this.video.style.position="fixed";
      //this.video.style.left="80px";
      //this.video.style.top="80px";
      //this.video.style.width="50px";
      //this.video.style.height="50px";
      //this.video.style.zIndex="3000";
      //this.video.style.opacity="0";
      //document.body.appendChild(this.video);
      // TODO: remove video from body at some point
    //}

    var proceed = () => {
      var frameCount = Math.floor(this.video.duration * fps);

      var framesMeta = [];
      var timecode = 0;
      for(var i = 0; i < frameCount; i++) {
        framesMeta.push({ delay: step, timecode })
        timecode += step;
      }

      opts.onLoad({
        width: this.video.videoWidth,
        height: this.video.videoHeight,
        duration: timecode,
        framesMeta: framesMeta,
      });
    }

    if(isIos) {
      var proceedInterval = setInterval(() => {
        if((this.video.duration === Infinity || isNaN(this.video.duration)) && this.video.readyState < 2) {
          this.video.currentTime = 10000000*Math.random();
        }
        else {
          clearInterval(proceedInterval);
          proceed();
        }
      }, 1000);
    }
    else {
      this.video.addEventListener("loadedmetadata", proceed);
    }

    this.video.addEventListener("error", opts.onError);

    this.video.src = this.url;

    //this.video.load();
    //this.video.playbackRate = 0.1;

    this.seekCallback = null;

    this.video.addEventListener("timeupdate", () => {
      //console.log("timeupdate")
      //if(isIos) {
        //if(lastTimecode < this.video.currentTime) {
          //lastTimecode = this.video.currentTime;
          //setTimeout(() => {
            //this.video.pause();
            //setTimeout(() => {
              //this.onSeek();
            //}, 10);
          //}, 0);
        //}
      //}
      //else {
        this.onSeek();
      //}
    }, false );

    if(isIos) {
      this.video.load();
    }

    //this.video.load();
    //
    //setTimeout(() => {
    //  this.onSeek();
    //}, 2000);
  }

  seekFrame(frameno) {
    return this.seekTimecode(step * frameno);
  }

  seekTimecode(timecode) {
    return new Promise((resolve) => {
      this.seekCallback = resolve;
      this.video.currentTime = timecode/1000 + 0.00001;
      //if(isIos) this.video.play();
    });
  }

  onSeek() {
    if(this.seekCallback) {
      this.seekCallback({ frame: this.video, delay: step });
      this.seekCallback = null;
    }
  }
}
