export default class Connection {
  constructor(url) {
    this.url = url;
    this.eventHandlers = {};
  }

  on(event, handler) {
    var handlers = this.eventHandlers[event];
    if(handlers == undefined) {
      handlers = [];
      this.eventHandlers[event] = handlers;
    }
    handlers.push(handler);
  }

  emit(event, ...args) {
    if(this.eventHandlers[event] == undefined) return;
    this.eventHandlers[event].forEach((handler) => {
      handler(...args);
    });
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.socket = new WebSocket(this.url);

      this.socket.onmessage = this._onMessage.bind(this);
      this.socket.onclose = this._onClose.bind(this);
      this.socket.onerror = this._onError.bind(this);
      this.socket.onopen = resolve;
    });
  }

  close() {
    if(this.socket) {
      this.socket.close();
    }
    this.socket = null;
  }

  send(message) {
    var attachments = message['attachments'];
    if(attachments == undefined) {
      attachments = [];
    }
    else {
      delete message['attachments'];
      message.attachments = attachments.length;
    }
    this.socket.send(JSON.stringify(message));
    for(var i = 0; i < attachments.length; i++) {
      this.socket.send(attachments[i]);
    }
  }

  _onMessage(event) {
    if(this.receivingAttachments) {
      this.receivedAttachments.push(event.data);
      if(this.receivedAttachments.length == this.receivingAttachmentsCount) {
        this.emit(this.receivedMessage.type, {
          ...this.receivedMessage,
          attachments: this.receivedAttachments
        });

        this.receivedMessage = null;
        this.receivingAttachments = false;
        this.receivingAttachmentsCount = null;
        this.receivedAttachments = null;
      }
    }
    else {
      var msg = JSON.parse(event.data);
      if('attachments' in msg) {
        this.receivedMessage = msg;
        this.receivedAttachments = [];
        this.receivingAttachmentsCount = msg.attachments;
        this.receivingAttachments = true;
      }
      else {
        this.emit(msg.type, { ...msg, attachments: [] });
      }
    }
  }

  _onClose(event) {
    if(event.wasClean) {
      if(event.code && event.code >= 4400 && event.code <= 4599) {
        this.emit("error", event);
      }
    } else {
      this.emit("error", event);
    }
  }

  _onError(event) {
    // "error" event fires only before close event anyway
  }
};
