import $ from 'jquery';

const CHUNK_BASE = 262144; // 256 KB
const MIN_CHUNK_SIZE = 8 * CHUNK_BASE; // 2 MB
const MAX_CHUNK_SIZE = 32 * MIN_CHUNK_SIZE; // 8 MB

// a chunk should be just a few percent of the total file size,
// so you barely lose any progress if the request fails
const IDEAL_CHUNK_PERCENT = 3;

export default class ChunkedUpload {
  constructor(opts) {
    this.file = opts.file;
    this.url = opts.url;
    this.chunkSize = this.idealChunkSize();

    this.chunksUploaded = 0;
    this.chunksTotal = Math.ceil(this.file.size / this.chunkSize);
    this.onComplete = opts.onComplete;
    this.onProgress = opts.onProgress;
    this.onFail = opts.onFail;

    this.retries = 0;
    this.cancelled = false;
  }

  idealChunkSize() {
    var chunkSize = Math.ceil(IDEAL_CHUNK_PERCENT / 100 * this.file.size / MIN_CHUNK_SIZE) * MIN_CHUNK_SIZE;
    if(chunkSize < MIN_CHUNK_SIZE) return MIN_CHUNK_SIZE;
    if(chunkSize > MAX_CHUNK_SIZE) return MAX_CHUNK_SIZE;
    return chunkSize;
  }

  start() {
    this.uploadNextChunk();
  }

  uploadNextChunk() {
    if(this.cancelled) return;
    this.uploadChunk(this.chunksUploaded).then((result) => {
      this.retries = 0;
      this.chunksUploaded++;
      if(result == 'continue') {
        if(this.chunksUploaded < this.chunksTotal) {
          this.onProgress({ progress: this.chunksUploaded/this.chunksTotal });
          this.uploadNextChunk();
        }
        else {
          this.onFail('Missing already uploaded chunks');
        }
      }
      else if(result == 'finished') {
        if(this.chunksUploaded < this.chunksTotal) {
          this.onFail('Not all chunks were uploaded');
        }
        else {
          this.onProgress({ progress: 1.0 });
          this.onComplete();
        }
      }
    }).catch((e) => {
      if(e.retryable) {
        var max_retries = 100;
        if(this.retries > max_retries) {
          this.onFail(e.message);
        }
        else {
          var max_backoff = 32000;
          var backoff = Math.min((Math.pow(2, this.retries) + Math.random()) * 1000, max_backoff);
          setTimeout(() => this.uploadNextChunk(), backoff);
          this.retries++;
        }
      }
      else {
        this.onFail(e.message);
      }
    });
  }

  cancel() {
    this.cancelled = true;
  }

  uploadChunk(index) {
    return new Promise((resolve, reject) => {
      const start = index * this.chunkSize;
      const end = start + this.chunkSize;
      const slice = this.file.slice(start, end);

      getData(slice).then((data) => {
        $.ajax({
          method: "PUT",
          url: this.url,
          headers: {
            'Content-Range': `bytes ${start}-${start+slice.size-1}/${this.file.size}`
          },
          processData: false,
          data: data,
          contentType: this.file.type,
          xhr: () => {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", (evt) => {
              if(evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                this.onProgress({ progress: (this.chunksUploaded + percentComplete)/this.chunksTotal });
              }
            }, false);

            xhr.addEventListener("progress", function(evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                this.onProgress({ progress: (this.chunksUploaded + percentComplete)/this.chunksTotal });
              }
            }, false);

            return xhr;
          },
          complete: (jqXHR, textStatus) => {
            switch(jqXHR.status) {
              case 308:
                return resolve('continue');
              case 200:
              case 201:
                return resolve('finished');
              case 408:
              case 500:
              case 502:
              case 503:
              case 504:
              case 0:
                return reject({message: `Status ${jqXHR.status}`, retryable: true});
              default:
                return reject({message: `${textStatus} (http status ${jqXHR.status})`, retryable: false});
            }
          }
        });
      }) 
    })
  }
}

function getData(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}