import $ from 'jquery';

$(() => {
  var faq = $(".js-faq");
  if(faq.length > 0) {

    function showFAQ(target, opts={scroll: false}){
      if(target == '#') return false;
      var target_el = $(target);
      if(target_el.length == 0) return false;
      if(opts.scroll) {
        var top = target_el.offset().top;
        $('html,body').animate({ scrollTop: top - 120 }, 500);
      }
      var toggle_link = target_el.find(".js-faq-toggle-link");
      if(toggle_link.length == 1 && toggle_link.data("visible") != true) {
        toggle_link.click();
      }
      return true;
    }
    $('a[href*=\\#]').on('click', function(event){     
      if(showFAQ(this.hash, {scroll: true})) event.preventDefault();
    });
    $(document).ready(function () {
      showFAQ(location.hash, {scroll: false});
      window.scrollBy(0, -50);
    });

    $(".js-faq .js-faq-toggle-link").click(function(e) {
      e.preventDefault()
      var link = $(this);
      var visible = link.data("visible") == true;
      var faq = $(this).closest(".js-faq");
      if(visible) {
        faq.find(".js-faq-answer").slideUp();
        faq.find(".js-faq-icon-default").show();
        faq.find(".js-faq-icon-active").hide();
      }
      else {
        faq.find(".js-faq-answer").slideDown();
        faq.find(".js-faq-icon-default").hide();
        faq.find(".js-faq-icon-active").show();
      }
      link.data("visible", !visible)
    });
  }

})
