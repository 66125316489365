<template>
  <div>
    <div class="backdrop" @click="$emit('close')"></div>
    <div class="wrapper d-flex align-items-center justify-content-center flex-row">
      <div :class="['modal-center', (mobileFullWidth ? 'mobile-full-width' : ''), (mobileFullHeight ? 'mobile-full-height' : ''), ]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobileFullWidth: {
      type: Boolean,
      default: false
    },
    mobileFullHeight: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {

    }
  },
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2490;
  background-color: rgba(0,0,0,0.5);
}
.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2500;
  pointer-events: none;
}
.modal-center {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  pointer-events: auto;
}

.mobile-full-width {
  width: 100%;
}
@media(min-width: 768px) {
  .mobile-full-width {
    width: auto;
  }
}

.mobile-full-height {
  height: 100%;
}
@media(min-width: 768px) {
  .mobile-full-height {
    height: auto;
  }
}
</style>
