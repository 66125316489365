<template>
  <div class="text-center my-3 pt-1">
    <div class="text-primary">
      <span class="h2" style="line-height: 1;">{{ price }}</span>
    </div>
    <span class="text-primary small bold">{{ billing }}</span>
  </div>
</template>

<script>

export default {
  props: {
    price: String,
    billing: String,
  },
}
</script>

<style scoped>
</style>
