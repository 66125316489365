import $ from 'jquery';

$(() => {
  $(".home-scroll-down-link").click(function(e) {
    e.preventDefault();
    var target = $($(this).data("target"));
    var offset = target.offset().top;
    $("html, body").animate({scrollTop: offset-50})
  });
});
