import tippy from 'tippy.js';

import $ from 'jquery';

import './tooltips.scss';

//tippy.setDefaults({
//  arrow: true,
//  theme: "light"
//})
//
$(() => {
  tippy('.hover-tooltip')
});

export default {
  create: (el) => {
    tippy(el);
  },
  destroy: (instance) => {
    instance.destroy();
  }
}

//import "tippy.js/themes/light.css";
