import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle", "content"]

  connect() {
    this.change();
  }

  change() {
    this.contentTarget.hidden = !this.toggleTarget.checked;
  }
}
