import Cookie from '../src/cookie';

import $ from 'jquery';

$(() => {
  $(".hide-promo-banner").click((e) => {
    e.preventDefault();
    $(".promo-banner").remove();
    Cookie.set('promobanner', 'functional', 'dismissed');
  });
})
