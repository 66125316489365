export default class TransparentVideo {
  constructor(opts) {
    this.url = opts.url;
    this.target = opts.target;
    this.class = opts.class;
    this.onLoad = opts.onLoad;
    this.onError = opts.onError;

    this.initVideo();
    this.loadVideo();
  }

  initVideo() {
    this.video = document.createElement("video");
    this.video.crossOrigin = "anonymous";
    this.video.playsInline = "playsInline";
    this.video.muted = "muted";
    this.video.loop = "loop";
    this.video.addEventListener("canplaythrough", () => this.videoReady(), { once: true });
    //this.target.appendChild(this.video);
  }

  loadVideo() {
    var req = new XMLHttpRequest();
    req.open('GET', this.url, true);
    req.responseType = 'blob';

    req.onload = () => {
      if(req.status === 200) {
        var videoBlob = req.response;
        var videoUrl = URL.createObjectURL(videoBlob); // TODO release URL later
        this.video.src = videoUrl;
      }
      else {
        this.onError(`Download failed: ${req.status}`);
      }
    }
    req.onerror = () => {
      this.onError("Download failed.");
    }

    req.send();
  }

  videoReady() {
    this.canvas = document.createElement("canvas");
    this.canvas.width = this.video.videoWidth / 2;
    this.canvas.height = this.video.videoHeight;
    this.canvas.classList.add(...this.class.split(' '));
    this.target.appendChild(this.canvas);

    this.setup();
  }

  setup() {
    this.gl = this.canvas.getContext("webgl");

    const shaderSet = [
      {
        type: this.gl.VERTEX_SHADER,
        id: "vertex-shader"
      },
      {
        type: this.gl.FRAGMENT_SHADER,
        id: "fragment-shader"
      }
    ];

    this.shaderProgram = this.buildShaderProgram(shaderSet);

    var vertexArray = new Float32Array([
      -0.5, 0.5, 0.5, 0.5, 0.5, -0.5,
      -0.5, 0.5, 0.5, -0.5, -0.5, -0.5
    ]);

    this.vertexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, vertexArray, this.gl.STATIC_DRAW);

    this.vertexNumComponents = 2;
    this.vertexCount = vertexArray.length/this.vertexNumComponents;

    this.initTexture();

    this.textureCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureCoordBuffer);

    const textureCoordinates = [
      // Front
      0.0,  0.0,
      1.0,  0.0,
      1.0,  1.0,
      0.0,  0.0,
      1.0,  1.0,
      0.0,  1.0,
    ];

    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(textureCoordinates),
      this.gl.STATIC_DRAW
    );

    this.video.play();

    this.onLoad(this.canvas);

    this.animateScene();
  }

  initTexture() {
    this.texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);

    // Because video havs to be download over the internet
    // they might take a moment until it's ready so
    // put a single pixel in the texture so we can
    // use it immediately.
    const level = 0;
    const internalFormat = this.gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = this.gl.RGBA;
    const srcType = this.gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([0, 0, 255, 255]);  // opaque blue
    this.gl.texImage2D(this.gl.TEXTURE_2D, level, internalFormat,
                  width, height, border, srcFormat, srcType,
                  pixel);

    // Turn off mips and set  wrapping to clamp to edge so it
    // will work regardless of the dimensions of the video.
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
  }

  updateTexture() {
    const level = 0;
    const internalFormat = this.gl.RGBA;
    const srcFormat = this.gl.RGBA;
    const srcType = this.gl.UNSIGNED_BYTE;
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, level, internalFormat,
                  srcFormat, srcType, this.video);
  }

  buildShaderProgram(shaderInfo) {
    let program = this.gl.createProgram();

    shaderInfo.forEach((desc) => {
      let shader = this.compileShader(desc.id, desc.type);

      if(shader) {
        this.gl.attachShader(program, shader);
      }
    });

    this.gl.linkProgram(program)

    if(!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
      console.log("Error linking shader program:");
      console.log(this.gl.getProgramInfoLog(program));
    }

    return program;
  }

  compileShader(id, type) {
    let code = shaders[id];
    let shader = this.gl.createShader(type);

    this.gl.shaderSource(shader, code);
    this.gl.compileShader(shader);

    if(!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
      console.log(`Error compiling ${type === this.gl.VERTEX_SHADER ? "vertex" : "fragment"} shader:`);
      console.log(this.gl.getShaderInfoLog(shader));
    }
    return shader;
  }

  animateScene() {
    this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
    this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
    //this.gl.colorMask(false, false, false, true);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.gl.useProgram(this.shaderProgram);

    this.updateTexture();

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);

    var aVertexPosition = this.gl.getAttribLocation(this.shaderProgram, "aVertexPosition");

    this.gl.enableVertexAttribArray(aVertexPosition);
    this.gl.vertexAttribPointer(aVertexPosition, this.vertexNumComponents,
          this.gl.FLOAT, false, 0, 0);

    {
      const numComponents = 2;
      const type = this.gl.FLOAT;
      const normalize = false;
      const stride = 0;
      const offset = 0;
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureCoordBuffer);
      this.gl.vertexAttribPointer(
          this.gl.getAttribLocation(this.shaderProgram, 'a_texCoord'),
          numComponents,
          type,
          normalize,
          stride,
          offset);
      this.gl.enableVertexAttribArray(
          this.gl.getAttribLocation(this.shaderProgram, 'a_texCoord'));
    }

    // Tell WebGL we want to affect texture unit 0
    this.gl.activeTexture(this.gl.TEXTURE0);

    // Bind the texture to texture unit 0
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);

    // Tell the shader we bound the texture to texture unit 0
    this.gl.uniform1i(this.gl.getUniformLocation(this.shaderProgram, 'u_image'), 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.vertexCount);

    requestAnimationFrame(() => this.animateScene());
  }

}



const shaders = {
  "vertex-shader": `
    attribute vec2 aVertexPosition;
    attribute vec2 a_texCoord;
    varying vec2 v_texCoord;

    void main() {
      gl_Position = vec4(aVertexPosition, 0, 0.5);
      v_texCoord = a_texCoord;
    }
  `,
  "fragment-shader": `
    precision mediump float;

    uniform sampler2D u_image;
    varying vec2 v_texCoord;

    void main() {
      vec4 color = texture2D(u_image, vec2(v_texCoord[0]*0.5, v_texCoord[1]));
      vec4 alpha = texture2D(u_image, vec2(v_texCoord[0]*0.5+0.5, v_texCoord[1]));
      gl_FragColor = color;
      gl_FragColor[0] = gl_FragColor[0] * alpha[0];
      gl_FragColor[1] = gl_FragColor[1] * alpha[0];
      gl_FragColor[2] = gl_FragColor[2] * alpha[0];
      gl_FragColor[3] = alpha[0];
    }
  `,
};

