import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "item"]

  connect() {
    this.change();
  }

  change() {
    let value = this.inputTarget.value;

    this.itemTargets.forEach(el => {
      el.hidden = (el.dataset.associatedValue != value);
    })
  }
}
