import Vue from 'vue/dist/vue.esm'

import UploadPage from '../components/clips/upload_page.vue'

import { getUploaderTranslations } from './get_meta';

import $ from 'jquery';

var uploader = null;

function initUploader(el) {
  uploader = new Vue({
    el,
    render: h => h(UploadPage, {
      ref: "uploadpage",
      props: {
        t: getUploaderTranslations(),
        mode: $(el).data("mode"),
      }
    }),
  })
}

window.addEventListener('popstate', (event) => {
  location.reload();
});

export function showUploadPage(mode) {
  if(mode == 'free_or_pro') {
    if(uploader && uploader.$refs.uploadpage.currentMode) {
      mode = uploader.$refs.uploadpage.currentMode;
    }
    else {
      mode = 'free';
    }
  }

  var t = getUploaderTranslations();

  var t_title = mode == 'free' ? t.title : t.pro.title;
  var t_url = mode == 'free' ? t.url : t.pro.url;
  var t_meta_description = mode == 'free' ? t.meta_description : t.pro.meta_description;

  if(!uploader || uploader.$refs.uploadpage.currentMode != mode) {
    window.history.pushState({}, t_title, t_url);

    var title = $("title");
    title.text(t_title);

    var canonical = $("link[rel='canonical']");
    if(canonical.length == 0) {
      canonical = $("<link rel='canonical' />");
      $('head').append(canonical);
    }
    canonical.attr("href", t_url);

    var meta_description = $("meta[name='description']");
    if(meta_description.length == 0) {
      meta_description = $("<meta rel='description' />");
      $('head').append(meta_description);
    }
    meta_description.attr("content", t_meta_description);
  }

  if(uploader && uploader.$refs.uploadpage.currentMode) {
    uploader.$refs.uploadpage.currentMode = mode;
  }

  if(!uploader) {
    $("#page-content").html("<div class='upload-page-wrapper'></div>");
    $(".upload-page-wrapper").data("mode", mode);

    initUploader($(".upload-page-wrapper")[0]);
  }
}

export function uploadFile(file, mode = 'free_or_pro') {
  showUploadPage(mode);
  Vue.nextTick(() => uploader.$refs.uploadpage.filePicked(file));
}

export function uploadUrl(url, mode = 'free_or_pro') {
  showUploadPage(mode);
  Vue.nextTick(() => uploader.$refs.uploadpage.urlPicked(url));
}

export function uploadGif(gif, mode = 'free_or_pro') {
  showUploadPage(mode);
  Vue.nextTick(() => uploader.$refs.uploadpage.gifPicked(gif));
}

$(() => {
  var uploader = $(".upload-page-wrapper");
  if(uploader.length == 0) return;
  initUploader(uploader[0]);
});
