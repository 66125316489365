import $ from 'jquery';
import Rails from "@rails/ujs";

$(() => {
  var issue_btn = $(".issue-api-key-btn");

  issue_btn.click(() => {
    issue_btn.attr("disabled", true);

    $.ajax({
      url: issue_btn.data("url"),
      method: 'POST',
      headers: { 'X-CSRF-Token': Rails.csrfToken() },
    }).then((data) => {
      $(issue_btn.data("parent")).html(data);
    }).catch((e) => {
      issue_btn.attr("disabled", false);
      alert("Failed to load API key.\nPlease try again or contact support@unscreen.com if the issue persists.")
    })
  })

  var show_btn = $(".reveal-api-key-btn");

  show_btn.click(() => {
    show_btn.addClass("d-none");
    $(show_btn.data("target")).removeClass("d-none");
  })

})
