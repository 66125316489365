export default class ColorLayer {
  constructor(opts) {
    this.value = opts.value;
  }

  renderTimecodeTo(timecode, canvas, ctx) {
    return this.renderFrameTo(0, canvas, ctx);
  }

  renderFrameTo(frameno, canvas, ctx) {
    ctx.fillStyle = this.value;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
}
