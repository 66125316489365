window.reportSplit = (experiment, goal = null, reset = true ) => {
  var data = {
    exp: experiment,
    reset: reset,
  };

  if(goal) data.goal = goal;

  $.ajax({
    type: "POST",
    url: "/split_metrics",
    data: data,
  })
}
