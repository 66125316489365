import loadImage from './load_image.js';
import drawImageProp from './draw_image_prop.js';

export default class ImageLayer {
  constructor(opts) {
    this.url = opts.url;
    this.imagePromise = loadImage(opts.url);
  }

  renderTimecodeTo(timecode, canvas, ctx) {
    return this.renderFrameTo(0, canvas, ctx);
  }

  // eslint-disable-next-line no-unused-vars
  renderFrameTo(frameno, canvas, ctx) {
    return this.imagePromise.then((image) => {
      drawImageProp(ctx, image, image.width, image.height, 0, 0, canvas.width, canvas.height, 0.5, 0.5);
    });
  }
}
