function formatTimecode(timecode) {
  if(timecode == undefined || timecode == null) return null;
  var min = Math.floor(timecode / 60000);
  var sec = Math.ceil((timecode % 60000) / 1000);
  if(min < 10) min = "0" + min;
  if(sec < 10) sec = "0" + sec;
  return `${min}:${sec}`;
}

export default formatTimecode;
