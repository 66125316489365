import $ from 'jquery';

$(() => {
  var dropdown = $(".navbar-user-dropdown");
  if(dropdown.length > 0) {
    dropdown.on("shown.bs.dropdown", (e) => {
      $(".subnav").css({zIndex: 500})
    })

    dropdown.on("hidden.bs.dropdown", (e) => {
      $(".subnav").css({zIndex: ''})
    })
  }
});
