import $ from 'jquery';

$(() => {
  $(".js-subscribe-form").submit(function(e) {
    e.preventDefault();

    var form = $(this);
    var data = new FormData(this);
    var email = form.find("input[name='email']").val();
    if(email.length < 5) return; // "a@b.c" == 5

    var btn = form.find("*[type='submit']");
    btn.attr("disabled", true);

    var action = $(this).attr("action");
    $.ajax({
      type: "POST",
      url: action,
      crossDomain: true,
      data: data,
      dataType: "json",
      contentType: "multipart/form-data",
      processData: false,
      contentType: false,
      headers: {
        "Accept": "application/json"
      }
    }).done(function() {
       form.html("<em>Thanks for signing up. We will keep you posted!</em>");
    }).fail(function() {
       alert('An error occurred. Please try again later.')
    });
  });

})