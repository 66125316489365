import { Controller } from "stimulus"
import { hcaptcha_invisible_submit } from '../src/hcaptcha';

export default class extends Controller {
  static targets = [ "content", "submitRow", "charCounter", "form", "token", "submitButton"]

  connect() {
    this.updateCharCounter()

    if(this.formTarget.dataset.nested == "true") {
      this.showSubmitDetails()
    } else {
      this.hideOrShowSubmitDetails()
    }
  }

  contentChanged(event) {
    this.hideOrShowSubmitDetails()
    this.updateCharCounter()
  }

  hideOrShowSubmitDetails() {
    // Always show submit row for nested comments
    if(this.formTarget.dataset.nested == "true") {
      return
    }

    if(this.contentTarget.value.length > 0) {
      this.showSubmitDetails()
    } else {
      this.hideSubmitDetails()
    }
  }

  showSubmitDetails() {
    $(this.submitRowTarget).fadeIn()
    $(this.charCounterTarget.parentElement).fadeIn()
  }

  hideSubmitDetails() {
    $(this.submitRowTarget).fadeOut()
    $(this.charCounterTarget.parentElement).fadeOut()
  }

  updateCharCounter() {
    this.charCounterTarget.textContent = this.contentTarget.value.length
  }

  requestCaptcha(e) {
    e.preventDefault();
    if (!this.formTarget.checkValidity()) {
      return
    }

    $(this.submitButtonTarget).attr("disabled", true);

    hcaptcha_invisible_submit(this.formTarget, {
      sitekey: this.data.get("hcaptcha-sitekey"),
    }).finally(() => {
      $(this.submitButtonTarget).attr("disabled", false);
    })
  }
}
