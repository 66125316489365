<template>
  <div class="my-3 px-3 position-relative upload">
    <a class="btn video-result--delete-btn" title="Delete video" href="#" v-on:click.prevent="deleteUpload" v-if="status == 'finished' || status == 'error'">×</a>
    <div v-if="status == 'queued'" class="py-5">
      <h2 class="text-center">
        Waiting for previous videos...
      </h2>
    </div>
    <div v-if="status == 'error'" class="alert alert-danger mx-auto" style="max-width: 600px;">
      <p v-if="errorOpts.html !== true">
        <strong>{{ error }}</strong>
      </p>
      <div v-if="errorOpts.html === true" v-html="error"></div>
      <p v-if="errorOpts.retryLater !== false">
        If you recorded the video on iPhone, read our <a href="/faq#iphone-videos" target="_blank">Tips how to process iPhone videos.</a>
        If the problem persists, contact support@unscreen.com.
      </p>
    </div>
    <div v-if="status == 'processing' && !captchaSolved" class="py-5">
      <h2 class="text-center">
        Preparing upload...
      </h2>
    </div>
    <div class="row" v-if="status == 'processing' || status == 'finished'">
      <div class="col-md-9 py-3" :style="captchaSolved ? '' : 'position: fixed; opacity: 0.0; left: -10000px; top: -10000px;'">
        <editor-new ref="editor" :progress="processingStatus == 'processing' ? previewProgress : null" :width="width" :height="height">
          <template v-slot:original>
            <div v-if="originalType && originalType.startsWith('video/')">
              <video crossOrigin="anonymous" autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline" style="width: 100%; height: 100%;">
                <source :src="originalUrl" :type="originalType" />
              </video>
            </div>
            <img :src="originalUrl" v-if="originalType && originalType.startsWith('image/')" class="img-fluid" />
          </template>
          <template v-slot:foreground>
            <canvas ref="preview" v-if="processingStatus == 'processing'" class="img-fluid" />
            <img :src="previewImage" v-if="processingStatus == 'finished'" class="img-fluid" :width="width" :height="height" />
          </template>
        </editor-new>
      </div>
      <div v-if="processingStatus == 'finished'" class="col-md-3 align-self-stretch d-flex flex-column" style="background: #454545;">
        <div style="background: #323232" class="row py-3 py-md-4 mt-4 flex-grow-1">
          <div class="col">
            <div class="mx-auto" style="max-width: 200px;">
              <div class="small font-weight-bold">Free Clip</div>
              <div class="text-muted" style="font-size: 80%;" v-if="videoWasCutOff">
                Note: Clip length limited to {{ Math.round(this.t.max_duration / 1000) }} seconds.
              </div>

              <div class="mt-md-3">
                <button class="btn btn-primary w-100" @click="shareGif" v-if="supportsSharing">
                  <i class="fas fa-share-alt mr-1"></i> Share
                </button>
              </div>
              <div>
                <div class="btn-group w-100 my-3">
                  <button :class="['btn', (supportsSharing ? 'btn-outline-primary' : 'btn-primary')]" @click="download('gif')">
                    Download
                  </button>
                  <button type="button" :class="['btn', (supportsSharing ? 'btn-outline-primary' : 'btn-primary'), 'dropdown-toggle dropdown-toggle-split px-2']" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="opacity: 0.8; max-width: 40px;">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <a href="#" class="dropdown-item" @click.prevent="download('gif')">
                      GIF
                    </a>
                    <a href="#" class="dropdown-item" @click.prevent="download('apng')">
                      Animated PNG
                    </a>
                    <a href="#" class="dropdown-item" @click.prevent="download('zip')">
                      Single Frames
                    </a>
                  </div>
                  <download-progress :progress="downloadProgress" :step="downloadStep" v-if="downloading" @close="abortDownload" />
                </div>
              </div>
              <div>
                <rate-widget :getFrames="function() { return frames.map((frame) => frame.rgb); }" :improveToken="improveToken" />
              </div>
            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="mx-auto" style="max-width: 200px;">

            <div class="small font-weight-bold mb-md-4">Unscreen Pro <span class="badge-left">NEW</span></div>

            <div class="small">
              <div class="my-1">
                <div class="d-inline-block mr-1" style="width: 20px;">
                  <img :src="t.icon_no_watermark_url" alt="" class="img-fluid" />
                </div>
                <tippy content="<strong>Use videos however you like:</strong> No need to mention Unscreen, the result contains no logo.">
                  <span class="dotted">No watermark</span>
                </tippy>
              </div>
              <div class="my-1">
                <div class="d-inline-block mr-1" style="width: 20px;">
                  <img :src="t.icon_full_length_url" alt="" class="img-fluid" />
                </div>
                <tippy content="<strong>Process videos with unlimited length</strong> for background-free footage of minutes or hours, not just seconds.">
                  <span class="dotted">
                    <span v-if="originalDuration > (resultDuration + 1000)">
                      Full Length: {{ formatTimecode(originalDuration) }} {{ originalDuration > 60000 ? 'minutes' : 'seconds' }}
                    </span>
                    <span v-else>
                      Full Length
                    </span>
                  </span>
                </tippy>
              </div>
              <div class="my-1">
                <div class="d-inline-block mr-1" style="width: 20px;">
                  <img :src="t.icon_hd_url" alt="" class="img-fluid" />
                </div>
                <tippy content="A <strong>video resolution of up to 1080p</strong> allows for high-accuracy results and professional use. That's almost 10 times more pixels than the free 360p resolution.">
                  <span class="dotted">
                    <span v-if="proPixelFactor >= 1.1">
                      Full HD: {{ proPixelFactor }} &times; more pixels
                    </span>
                    <span v-else>Full HD</span>
                  </span>
                </tippy>
              </div>
              <div class="my-1">
                <div class="d-inline-block mr-1" style="width: 20px;">
                  <img :src="t.icon_video_url" alt="" class="img-fluid" />
                </div>
                <tippy content="<strong>Download MP4 video files</strong> that you can easily use with your editing software. Framerate settings and audio tracks are preserved.">
                  <span class="dotted">
                    MP4 video
                  </span>
                </tippy>
              </div>
              <div class="my-1">
                <div class="d-inline-block mr-1" style="width: 20px;">
                  <img :src="t.icon_sound_url" alt="" class="img-fluid" />
                </div>
                <tippy content="<strong>Enjoy audio support</strong> every time you select Video MP4 or Pro Bundle as output formats. Animated Gif file formats cannot support sound.">
                  <span class="dotted">
                    Audio Support
                  </span>
                </tippy>
              </div>
            </div>

            <div class="mt-3">
              <button class="btn btn-outline-primary w-100" @click="submitToPro">
                Process with Pro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Composer from '../../core/composer.js';
import FrameExtractor from '../../core/frame_extractor.js';
import { APNGencoder } from '../../vendor/canvas2apng.js';
import LayerComposer from '../../core/layer_composer.js';

import FrameArrayLayer from '../../core/frame_array_layer.js';
import ImageLayer from '../../core/image_layer.js';
import VideoLayer from '../../core/video_layer.js';
import ColorLayer from '../../core/color_layer.js';
import loadImage from '../../core/load_image.js';
import Connection from '../../core/connection.js';
import applyVideoRotation from '../../core/apply_video_rotation.js';

import { getConfig } from '../../src/get_meta';
import { hcaptcha_invisible } from '../../src/hcaptcha';
import { pickFile } from '../../src/pick_file';
import formatTimecode from '../../src/timecode';
import checkQuota from '../../src/quota';

import DownloadProgress from './download_progress';
import RateWidget from './rate_widget';
import Editor from './editor';
import EditorNew from './editor_new';

import { saveAs } from 'file-saver';

import { AnimatedGIF, defaultOptions } from '../../vendor/gifshot.js';

import JSZip from 'jszip';
import $ from 'jquery';

const watermark = require('binary-loader!../../assets/made.png.raw');

var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export default {
  name: 'Upload',
  props: {
    status: String,
    t: Object,
    file: File,
    url: String,
    basename: String,
  },
  components: {DownloadProgress, RateWidget, EditorNew},
  data() {
    return {
      captchaSolved: false,
      processingStatus: "processing",
      error: "",
      errorOpts: {},
      previewImage: "",
      gifOverlay: false,
      frames: [],
      width: 0,
      height: 0,
      connection: new Connection(getConfig()['api_url']),
      originalUrl: null,
      originalType: null,
      downloading: false,
      downloadProgress: null,
      downloadStep: null,
      videoWasCutOff: false,
      extractedFrameCount: 0,
      framesSent: 0,
      framesComposited: 0,
      supportsSharing: navigator.canShare && navigator.canShare({files: [new File([], "unscreen.gif", { type: "image/gif", lastModified: new Date().getTime()})]}),
      improveToken: null,
      originalDuration: null,
      originalWidth: null,
      originalHeight: null,
      resultDuration: null,
      proPixelFactor: null,
      editorVariant: "new",
    }
  },
  watch: {
    status(_value) {
      this.startProcessingIfReady();
    }
  },
  computed: {
    previewProgress() {
      if(this.extractedFrameCount == 0) return 0;
      return (this.framesSent + this.framesComposited) / (2 * this.extractedFrameCount);
    },
    sanitizedBasename() {
      var base = this.basename ? this.basename : '';
      var sanitized = base.replace(/[^a-zA-Z0-9]+/g, '-');
      if(sanitized.length > 30) {
        sanitized = sanitized.slice(0, 30);
      }
      if(sanitized.length == 0) {
        sanitized = "video";
      }
      return sanitized;
    }
  },
  mounted() {
    this.startProcessingIfReady();
    this.editorVariant = $(`meta[property='split:editor']`).attr("content")
  },
    methods: {
      formatTimecode,
      submitToPro() {
      this.$emit('submitToPro', this.file ? { file: this.file } : { url: this.url });
    },
    startProcessingIfReady() {
      if(this.status == 'processing') {
        if(!checkQuota(this.t.quota)) {
          this.showError(
            `
              <p><strong>You have uploaded more than ${this.t.quota} clips today.</strong></p>
              <p style="margin-bottom: 0;">Please <a href="${this.t.pro.login_url}" style="color: inherit; text-decoration: underline;">sign up</a> to continue. It's free and takes just a minute.</p>
            `,
            { retryLater: false, html: true }
          )
          return;
        }

        this.startProcessing();
      }
    },
    startProcessing() {
      if(this.url) {
        this.urlPicked(this.url, "image/gif");
      }
      else if(this.file) {
        this.filePicked(this.file);
      }
    },
    prepareFile(file) {
      return new Promise((resolve, reject) => {
        if(file.type.startsWith("video/") && isIos) {
          applyVideoRotation(file).then(resolve).catch(() => {
            console.log("Applying video rotation failed");
            resolve({ fileOrBlob: file, processOpts: {} });
          });
        }
        else {
          resolve({ fileOrBlob: file, processOpts: {} });
        }
      });
    },
    filePicked(rawFile) {
      // TODO: validate file

      this.prepareFile(rawFile).then((result) => {

        var url = URL.createObjectURL(result.fileOrBlob);
        // TODO: URL.revokeObjectURL(this.fileUrl);

        window.track('Video', 'upload_file', 'Upload File');

        this.process(url, rawFile.type, result.processOpts);

      }).catch((e) => {
        this.showError(`Failed to prepare video file: ${e}`);
      })
    },
    deleteUpload() {
      this.$emit('delete', this.key);
    },
    urlPicked(url, mimeType) {
      url = url.replace(/\.webp(\?.*)?$/, ".gif$1");

      window.track('Video', 'upload_url', 'Upload URL');
      this.process(url, mimeType);
    },
    process(url, mimeType, opts={}) {
      this.originalUrl = url;
      this.originalType = mimeType;

      var captchaPromise = new Promise((resolve, reject) => {
        hcaptcha_invisible({ sitekey: getConfig()['hcaptcha_sitekey'] }).then((captcha) => {
          this.captchaSolved = true;
          resolve(captcha);
        }).catch(reject);
      });

      var maxFrameCount = Math.floor(30 * this.t.max_duration / 1000); // count

      var loadWatermark = loadImage('data:image/png;base64,'+btoa(watermark));

      var extractor = new FrameExtractor({
        url: url,
        mimeType: mimeType,
        rotation: opts.rotation,
        onError: (e) => {
          this.showError(`Failed to load video file: ${e}`);
        },
        onLoad: ({width, height, framesMeta, duration}) => {
          var extractedDuration = 0;

          while(this.extractedFrameCount < framesMeta.length && extractedDuration < this.t.max_duration && this.extractedFrameCount < maxFrameCount) {
            var frameMeta = framesMeta[this.extractedFrameCount];
            extractedDuration += frameMeta.delay;
            this.extractedFrameCount++;
          }

          this.videoWasCutOff = this.extractedFrameCount < framesMeta.length;
          this.resultDuration = extractedDuration;
          this.originalDuration = duration;

          var largerDim = width > height ? width : height;
          var smallerDim = width > height ? height : width;

          var scaleFactor = 1.0;
          if(smallerDim > 360) {
            scaleFactor = 360 / smallerDim;
          }
          if(scaleFactor * largerDim > 640) {
            scaleFactor = 640 / largerDim;
          }

          var extractedWidth = Math.floor(width * scaleFactor);
          var extractedHeight = Math.floor(height * scaleFactor);

          this.width = extractedWidth;
          this.height = extractedHeight;
          this.originalWidth = width;
          this.originalHeight = height;

          var proMaxRes = 1920 * 1080;
          var proRes = this.originalWidth * this.originalHeight;
          if(proRes > proMaxRes) proRes = proMaxRes;
          this.proPixelFactor = Math.round(10 * proRes / (this.width * this.height)) / 10;

          var previewCanvas = this.$refs.preview;
          var previewCtx = previewCanvas.getContext("2d");

          previewCanvas.width = extractedWidth;
          previewCanvas.height = extractedHeight;

          var encoder = new APNGencoder(previewCanvas);

          encoder.setDispose(1);
          encoder.start();

          //console.log(`Original video: ${width} x ${height} (${framesMeta.length} frames)`);
          //console.log(`Extracted video: ${extractedWidth} x ${extractedHeight} (${this.extractedFrameCount} frames)`);

          this.frames = [];
          var framesReceived = 0;
          var lastFrameReceived = -1;

          var composer = new Composer(extractedWidth, extractedHeight);

          var composeQueue = [];
          var composing = false;

          var previewCanvasLastUpdated = 0;

          var drawPreviewFrame = (frame) => {
            composer.compose(this.frames[0].rgb, frame).then((canvas) => {
              loadWatermark.then((watermark) => {
                if(lastFrameReceived != -1) return;
                previewCtx.drawImage(canvas, 0, 0);
                this.drawWatermark(previewCtx, watermark);
              });
            });
          };

          var processComposeQueue = () => {
            if(composeQueue.length == 0 || composing == true) return;
            composing = true;
            lastFrameReceived++;
            composer.compose(this.frames[lastFrameReceived].rgb, composeQueue.shift()).then((canvas) => {
              var now = new Date().getTime();
              var updateDiff = now - previewCanvasLastUpdated;
              //console.log(updateDiff)
              previewCanvasLastUpdated = now;

              var waitBeforePaint = this.frames[lastFrameReceived].delay - updateDiff;
              if(waitBeforePaint < 0) waitBeforePaint = 0;

              //console.log(waitBeforePaint);

              setTimeout(() => {
                previewCtx.clearRect(0, 0, extractedWidth, extractedHeight);
                previewCtx.drawImage(canvas, 0, 0);

                loadWatermark.then((watermark) => {
                  this.drawWatermark(previewCtx, watermark);

                  var rgbaDataUrl = previewCanvas.toDataURL('image/png');
                  this.frames[lastFrameReceived].rgba = rgbaDataUrl;

                  encoder.setDelay(this.frames[lastFrameReceived].delay / 10);
                  encoder.addFrame(rgbaDataUrl).then(() => {
                    composing = false;
                    this.framesComposited++;

                    if(lastFrameReceived == this.extractedFrameCount-1) {
                      encoder.finish();
                      var arrayBufferView = new Uint8Array( encoder.stream().getBytes() );
                      var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );

                      // Free memory
                      encoder.apngBytes = null;

                      this.previewImage = URL.createObjectURL(blob);
                      this.processingStatus = "finished"
                      // TODO: URL.revokeObjectURL at some point

                      this.$emit('finished');

                      if(window.hj) window.hj('trigger', 'processed');
                      window.track('Video', 'processed', 'Processed');
                    }
                    else {
                      processComposeQueue();
                    }
                  })
                });
              }, waitBeforePaint);
            });
          }

          this.connection.on("error", (event) => {
            var reason = event.reason ? event.reason : "Failed to connect to server. Are you connected to the Internet?";
            var code = event.code ? event.code : 0;

            this.showError(`${reason} (error code ${code})`);
          });
          this.connection.on("previewframe", (message) => {
            drawPreviewFrame(message.attachments[0]);
          });
          this.connection.on("outputframe", (message) => {
            composeQueue.push(message.attachments[0]);
            processComposeQueue();

            framesReceived++;
          });
          this.connection.on("done", (message) => {
            this.improveToken = message.improveToken;
            this.connection.close();
          });

          var sendInputFrames = () => {
            if(this.status == 'error') return;
            uploadReady.then(() => {
              if(this.framesSent >= this.frames.length) return;
              this.connection.send({ type: 'inputframe', attachments: [ this.frames[this.framesSent].rgb ] });
              this.framesSent++;
              sendInputFrames();
            })
          };

          var uploadReady = new Promise((resolve, reject) => {
            this.connection.on("readyforupload", resolve);
          });

          captchaPromise.then((captchaToken) => {
            this.connection.connect().then(() => {
              this.connection.send({
                type: 'startupload',
                frameCount: this.extractedFrameCount,
                original: {
                  format: mimeType,
                  width: width,
                  height: height,
                  lengthMs: duration,
                },
                captchaToken: captchaToken,
                captchaType: "hcaptcha",
              });
            })
          }).catch((e) => {
            console.log(e);
            this.showError("Captcha failed");
          });

          extractor.extractFrames(extractedWidth, extractedHeight, this.extractedFrameCount, (frame) => {
            if(this.status == 'error') {
              extractor.abort();
            }
            this.frames.push({rgb: frame.blob, delay: frame.delay, timecode: frame.timecode});
            sendInputFrames();
          }, (error) => {
            if(typeof(error) == "string") {
              this.showError(error);
            }
            else {
              this.showError("The video codec is not supported. Please select a different video file or adjust your camera settings to record in a more compatible format.");
            }
            this.connection.close();
          });

        }
      })
    },
    showError(message, opts = {}) {
      window.track('Video', 'processing_error', 'Processing Error');
      this.error = message;
      this.errorOpts = opts;
      this.$emit("error");
    },
    drawWatermark(ctx, img) {
      ctx.globalAlpha = 0.35;
      var watermarkWidth = this.width < 140 ? this.width : 140;
      var watermarkHeight = img.height / img.width * watermarkWidth;
      ctx.drawImage(img, this.width - watermarkWidth, this.height - watermarkHeight, watermarkWidth, watermarkHeight);
      ctx.globalAlpha = 1.0;
    },
    abortDownload() {
      this.downloading = false;
      // TODO: abort current compositing, encoding, etc.
    },
    download(format) {
      this.processDownload(format).then((blob) => {
        switch(format) {
          case "gif":
            saveAs(blob, `${this.sanitizedBasename}-unscreen.gif`);
            if(window.hj) window.hj('trigger', 'downloaded');
            window.track('Video', 'downloaded_gif', 'Downloaded GIF');
            break;
          case "apng":
            saveAs(blob, `${this.sanitizedBasename}-unscreen.png`);
            if(window.hj) window.hj('trigger', 'downloaded');
            window.track('Video', 'downloaded_apng', 'Downloaded APNG');
            break;
          case "zip":
            saveAs(blob, `${this.sanitizedBasename}-unscreen.zip`);
            if(window.hj) window.hj('trigger', 'downloaded');
            window.track('Video', 'downloaded_zip', 'Downloaded ZIP');
            break;
          default:
            alert(`Download failed: Unknown format '${format}'`);
        }
      })
    },
    shareGif() {
      this.processDownload('gif').then((blob) => {
        if(window.hj) window.hj('trigger', 'shared');
        window.track('Video', 'shared_gif', 'Shared GIF');
        var filename = `${this.sanitizedBasename}-unscreen.gif`;
        var file = new File([blob], filename, {
          type: "image/gif",
          lastModified: new Date().getTime()
        });
        navigator.share({
          files: [file],
          text: 'Made with unscreen.com'
        }).then(() => {
          // ok
        }).catch((e) => {
          // Sharing failed - most likely because not initiated by user gesture
          // This seems to happen if the processing takes too long
          // In that case, fallback to download
          saveAs(blob, filename);
        })
      })
    },
    composeFrames(frameFormat, bg) {
      return new Promise((resolve, reject) => {
        var layerComposer = new LayerComposer(frameFormat);

        if(bg.type == 'color') {
          layerComposer.add(new ColorLayer(bg));
        }
        else if(bg.type == 'video') {
          layerComposer.add(new VideoLayer(bg));
        }
        else if(bg.type == 'image') {
          if(bg.mimeType == 'image/gif') {
            layerComposer.add(new VideoLayer(bg));
          }
          else {
            layerComposer.add(new ImageLayer(bg));
          }
        }

        var idx = layerComposer.add(new FrameArrayLayer({
          src: this.frames.map((el) => { return { image: el.rgba, timecode: el.timecode }; }),
          width: this.width,
          height: this.height,
        }))

        layerComposer.setPrimary(idx);

        layerComposer.on("progress", (event) => {
          this.downloadProgress = event.percent*0.5;
        })

        layerComposer.setup().then(() => {
          layerComposer.process().then((frames) => {
            layerComposer.teardown();
            resolve(frames);
          });
        });
      })
    },
    processDownload(format) {
      return new Promise((resolve, reject) => {
        this.downloading = true;
        this.downloadProgress = 0;
        this.downloadStep = "Compositing...";

        var frameFormat = "png";

        if(format == "gif") {
          frameFormat = "raw";
        }

        var bg = this.$refs.editor.getConfig();

        if(format == 'zip' && bg.type != 'transparent') {
          frameFormat = 'jpg';
        }

        this.composeFrames(frameFormat, bg).then((frames) => {

          if(!this.downloading) return;

          this.downloadProgress = 50;
          this.downloadStep = "Encoding...";

          var frameCount = frames.length;

          if(format == "gif") {
            var opts = {
              ...defaultOptions.default,
              gifWidth: this.width,
              gifHeight: this.height,
              frameDuration: 1,
              numFrames: frames.length,
              images: frames,
              sampleInterval: 100,
              numWorkers: 4,
            };

            var ag = new AnimatedGIF(opts);
            ag.onRenderProgress((progress) => {
              this.downloadProgress = 50 + progress*50;
            });
            frames.forEach((frame, i) => {
              ag.addFrameImageData(frame, { delay: Math.round(this.frames[i].delay / 10) });
            });
            ag.getBase64GIF((blob) => {
              if(!this.downloading) return;
              this.downloadProgress = 100;
              this.downloadStep = "Downloading...";

              resolve(blob);
              setTimeout(() => this.downloading = false, 1000);
            });
          }
          else if(format == "apng") {
            var resultCanvas = document.createElement("canvas");

            resultCanvas.width = this.width;
            resultCanvas.height = this.height;

            var encoder = new APNGencoder(resultCanvas);
            encoder.setDispose(1);
            encoder.start();

            var p = Promise.resolve();

            frames.forEach((frame, i) => {
              p = p.then(() => {
                this.downloadProgress = 50 + 40 * i / frameCount;
                encoder.setDelay(this.frames[i].delay / 10);
                return encoder.addFrame(frame);
              });
            });

            p.then(() => {
              encoder.finish();

              var arrayBufferView = new Uint8Array( encoder.stream().bin );
              var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );

              if(!this.downloading) return;
              this.downloadProgress = 100;
              this.downloadStep = "Downloading...";

              resolve(blob);
              setTimeout(() => this.downloading = false, 1000);
            })
          }
          else if(format == "zip") {
            var zip = new JSZip();

            var b64regex = frameFormat == 'png' ? /^data:image\/png;base64,/ : /^data:image\/jpeg;base64,/;

            var i = 1;
            frames.forEach((frame) => {
              var data = frame.replace(b64regex, "");
              zip.file(`unscreen-${String(i++).padStart(3, '0')}.${frameFormat}`, data, {base64: true});
            });

            zip.generateAsync({type:"blob", streamFiles: true}, (metadata) => {
              this.downloadProgress = 50 + 0.5 * metadata.percent;
            }).then((blob) => {
              if(!this.downloading) return;
              this.downloadProgress = 100;
              this.downloadStep = "Downloading...";

              resolve(blob);
              setTimeout(() => this.downloading = false, 1000);
            });

          }

        });
      });
    }
  }
}
</script>

<style scoped>
.upload {
  background: #202020;
  margin-left: -15px;
  margin-right: -15px;
}

@media(min-width: 768px) {
  .upload {
    border-radius: 3px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>

