import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "submitRow", "charCounter" ]

  replyTo(event) {
    let data = JSON.parse(event.target.dataset.comment)
    this.showReplyForm(data)
  }

  showReplyForm(data) {
    let answer_anchor = `.reply-to-${data.id}`
    let comment_anchor = `#comment-${data.id}`

    if($(answer_anchor).is(":visible")) {
      return
    }

    $(".reply-to-comment").hide().promise().done(function() {
      $(answer_anchor).fadeIn("fast").promise().done(function() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(comment_anchor).offset().top - 50 // sticky navbar is 40px
        }, 1000);
      })
    })
  }
}