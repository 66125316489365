import loadImage from './load_image.js';

export default class Composer {
  constructor(width, height) {
    this.width = width;
    this.height = height;

    this.rgbCanvas = document.createElement("canvas");
    this.rgbCanvas.width = width;
    this.rgbCanvas.height = height;
    this.rgbCtx = this.rgbCanvas.getContext("2d");

    this.aCanvas = document.createElement("canvas");
    this.aCanvas.width = width;
    this.aCanvas.height = height;
    this.aCtx = this.aCanvas.getContext("2d");
  }

  compose(rgb, a) {
    return new Promise((composeResolve) => {
      var rgbUrl = URL.createObjectURL(rgb);
      var aUrl = URL.createObjectURL(a);

      Promise.all([
        loadImage(rgbUrl),
        loadImage(aUrl)
      ]).then((images) => {
        return new Promise((resolve) => {
          URL.revokeObjectURL(rgbUrl);
          URL.revokeObjectURL(aUrl);
          resolve(images);
        });
      }).then(([rgbImg, aImg]) => {
        this.rgbCtx.drawImage(rgbImg, 0, 0);
        this.aCtx.drawImage(aImg, 0, 0);

        var rgbPx = this.rgbCtx.getImageData(0, 0, this.width, this.height);
        var rgbPxData = rgbPx.data;
        var aPx = this.aCtx.getImageData(0, 0, this.width, this.height);
        var aPxData = aPx.data;

        for(var i = 0; i < rgbPxData.length; i += 4) {
          rgbPxData[i+3] = aPxData[i];
        }

        this.rgbCtx.putImageData(rgbPx, 0, 0);

        composeResolve(this.rgbCanvas);

        //this.rgbCanvas.toBlob(composeResolve, "image/png");
      });
    })
  }
}