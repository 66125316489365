<template>
  <div style="cursor: pointer;">
    Prices apply to 30 fps videos:
    <tippy content="<strong>Unscreen Pro supports any frame rate:</strong><br/> Videos with up to 30 fps are charged the regular price. Extra charges apply for higher framerates. <a href='/faq#higher-framerates'>Read more</a>" :interactive="true">
      <span style="color: inherit; text-decoration: underline; text-decoration-style: dotted;">What's the price for higher frame rates?</span>
    </tippy>
  </div>
</template>

<script>

export default {
}
</script>

<style scoped>
</style>
