<template>
  <div>
    <modal @close="$emit('close')" :mobileFullWidth="true">
      <div class="dlmodal px-3 py-2">

        <div class="mb-2">
          <strong>{{ step }}</strong>
        </div>

        <div class="progress download-progress mb-3">
          <div class="progress-bar progress-bar-striped" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${progress}%` }">
            {{ Math.round(progress) }}%
          </div>
        </div>

        <div class="text-center">
          <a href="#" @click.prevent="$emit('close')" class="btn btn-outline-light btn-sm">
            {{ progress == 100 ? "Close" : "Cancel" }}
          </a>
        </div>

      </div>
    </modal>
  </div>
</template>

<script>
import Modal from './modal';

export default {
  props: {
    progress: Number,
    step: String,
  },
  data: () => {
    return {

    }
  },
  components: { Modal },
  methods: {
  }
}
</script>

<style scoped>
.dlmodal {
  background: #535353;
}
@media(min-width: 768px) {
  .dlmodal {
    width: 360px;
    border-radius: 5px;
  }
}

.download-progress .progress-bar {
  transition: none;
}
</style>
