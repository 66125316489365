var count = 0;
var registered = false;

function handler(e) {
  if(count > 0) {
    var confirmationMessage = 'Are you sure you want to cancel the video upload?';

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }
}

function register() {
  if(registered) return;
  registered = true;
  window.addEventListener("beforeunload", handler);
}

export default {
  add() {
    count++;
    register();
  },
  remove() {
    count--;
  }
};
