window.track = function(category, action, label, item, transaction_id = null) {
  if (typeof (window.gtag) == "undefined") return;
  window.gtag("event", action, {
    "transaction_id": transaction_id,
    "currency": "USD",
    "event_category": category,
    "event_label": label,
    "items": [item]
  });
};
