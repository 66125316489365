<template>
  <span>
    <span v-if="supported" class="d-inline-block">
      <toggle-button :value="enabled" :color="{checked: '#2699FB', unchecked: '#9F9F9F'}" :sync="true" :width="30" :height="18" @change="toggle"></toggle-button>
      <span class="d-inline-block p-1" @click="toggle" style="cursor: pointer; vertical-align: middle;">Notify me when ready</span>
    </span>
  </span>
</template>

<script>
import Notifications from '../../src/notifications';

export default {
  data() {
    var enabled = Notifications.get();
    return {
      supported: Notifications.supported(),
      enabled: enabled,
      notificationId: null,
    }
  },
  mounted() {
    this.notificationId = Notifications.subscribe((value) => this.enabled = value);
  },
  onBeforeDestroy() {
    Notifications.unsubscribe(this.notificationId);
  },
  methods: {
    toggle() {
      if(this.enabled){
        this.disable();
      }
      else {
        this.enable();
      }
    },
    enable() {
      Notifications.enable();
    },
    disable() {
      Notifications.disable();
    }
  }
}

</script>

<style scoped>
.item {
  min-height: 50px;
  font-size: 115%;
}
.vue-js-switch {
  margin-bottom: 0;
}
</style>

