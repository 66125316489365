// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'script-loader!jquery';

import 'bootstrap/dist/js/bootstrap.bundle.js';

import '../vendor/modernizr';

import '../src/application.scss';

import '../src/auth';
import '../src/track';
import '../src/vue_mount';
import '../src/videos';
import '../src/subscribe_form';
import '../src/home';
import '../src/faq';
import '../src/before_after';
import '../src/promo';
import '../src/dashboard';
import '../src/nav';
import '../src/newsletter_optin';
import '../src/tooltips';
import '../src/api_key';
import '../src/split_metrics';

import Cookie from "../src/cookie.js";
window.Cookie = Cookie;

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
