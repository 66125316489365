<template>
  <div>
    <div ref="canvasWrapper"></div>
  </div>
</template>

<script>
import TransparentVideo from '../../core/transparent_video';

export default {
  props: {
    composite_video_url: String,
  },
  data() {
    return {
      width: 0,
      height: 0,
      video: null,
    }
  },
  mounted() {
    this.video = new TransparentVideo({
      url: this.composite_video_url,
      target: this.$refs.canvasWrapper,
      class: "d-block img-fluid",
      onLoad: (canvas) => {
        this.width = canvas.width;
        this.height = canvas.height;
      },
      onError: (message) => {
        alert(message);
      }
    });
  }
}

</script>

<style scoped>
</style>
